import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useWindowWidth } from "@react-hook/window-size";

// icon

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import Player from "components/Player/Player";

// style
import styles from "./FanclubLive.module.scss";

// constant
import { IMG_VARIATION_INFOMATION_DETAIL } from "const/const";

const FanclubLiveDetail = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [streamHeight, setStreamHeight] = useState(0);
  const [streamStyle, setStreamStyle] = useState(null);
  const onlyWidth = useWindowWidth();

  const [article] = useRest(
    `Planner/Schedule/${slug}`,
    { image_variation: IMG_VARIATION_INFOMATION_DETAIL },
    true
  );

  useEffect(() => {
    if (streamHeight === 0) {
      setStreamStyle(null);
    } else {
      if (onlyWidth < 768) {
        setStreamStyle({
          style: {
            paddingTop: `${streamHeight}px`,
          },
        });
      }
    }
  }, [streamHeight]);

  return (
    <>
      <InnerLayout headType="live" title={t("common_video")}>
        {article !== null && (
          <Player
            eventId={article.data.Planner_Event__}
            article={article}
            setStreamHeight={setStreamHeight}
          />
        )}
        <div
          className={`${styles["content-wrapper"]} ${styles["content-wrapper-live"]}`}
          {...streamStyle}
        >
          {article === null && (
            <div className={styles["content-container"]}>
              <Loading />
            </div>
          )}
          {article !== null && (
            <>
              <Helmet>
                <title>{`${article.data.Content_Cms_Entry_Data.Title} - ${t(
                  "common_title"
                )}`}</title>
                <meta
                  property="og:title"
                  content={`${article.data.Content_Cms_Entry_Data.Title} - ${t(
                    "common_title"
                  )}`}
                />
                <meta
                  property="og:site_name"
                  content={`${article.data.Content_Cms_Entry_Data.Title} - ${t(
                    "common_title"
                  )}`}
                />
              </Helmet>
              <div>
                <div className={styles["live-head"]}>
                  <p
                    className={styles["live-error"]}
                    dangerouslySetInnerHTML={{ __html: t("common_super_chat") }}
                  ></p>
                  <h1 className={styles["live-title"]}>
                    {article.data.Content_Cms_Entry_Data.Title}
                  </h1>
                </div>

                <div className={styles["content-container"]}>
                  {article.data.Content_Cms_Entry_Data.Short_Contents !==
                    "" && (
                    <p className={styles["live-title-sub"]}>
                      {article.data.Content_Cms_Entry_Data.Short_Contents}
                    </p>
                  )}
                  <div
                    className={styles["cms-contents"]}
                    dangerouslySetInnerHTML={{
                      __html: article.data.Content_Cms_Entry_Data.Contents,
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </InnerLayout>
    </>
  );
};

export default FanclubLiveDetail;
