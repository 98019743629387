import React from "react";
import ReactPlayer from "react-player";

// component
import Slick from "components/Slick/Slick";

// style
import styles from "./Slide.module.scss";

// const
import { SINGING_LIST } from "const/const";

const Slide = () => {
  return (
    <div className={`${styles["content-wrapper"]}`}>
      <Slick>
        {SINGING_LIST.map((val, index) => {
          if (index < 4) {
            return (
              <div key={index} className={styles["slide-item"]}>
                <ReactPlayer url={val.url} width="246px" height="138px" />
              </div>
            );
          } else {
            return false;
          }
        })}
      </Slick>
    </div>
  );
};

export default Slide;
