import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
import Toast, { error } from "components/Toast/Toast";

// component
import Button from "components/Btns/Btn";

// styles
import styles from "./Payment.module.scss";

// const

const AozoraNetWrapper = ({
  orderProcess = null,
  orderRefresh = null,
  cartRefresh = null,
  setOrderComp = null,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const submitAozoraNet = () => {
    setLoading(true);
    rest(`Order/${orderProcess.data.order.Order__}:process`, "POST", {
      method: orderProcess.data.methods.AozoraNet.method,
      session: orderProcess.data.methods.AozoraNet.session,
    })
      .then(() => {
        cartRefresh();
        orderRefresh();
        setOrderComp(true);
        setLoading(false);
      })
      .catch((data) => {
        setLoading(false);
        error(data.message, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      {orderProcess.data.order.Status !== "completed" && (
        <div className={styles["payment-stripe-wrapper"]}>
          {orderProcess.data.order.Status === "pending-initiated" && (
            <>
              <p className={styles["payment-stripe-title"]}>
                {t("order_aozoranet_title_comp")}
              </p>
              <p>
                {t("order_aozoranet_comp_text")}
                <br />
                ※振込手数料はお客様ご負担となります。
              </p>
              <p>銀行名：{orderProcess.data.order.Meta.AozoraNet_Bank_Name}</p>
              <p>
                支店名：{orderProcess.data.order.Meta.AozoraNet_Branch_Name}（
                {orderProcess.data.order.Meta.AozoraNet_Branch_Id}）
              </p>
              <p>
                普通口座：{orderProcess.data.order.Meta.AozoraNet_Account_No}
              </p>
              <p>
                口座名義：
                {orderProcess.data.order.Meta.AozoraNet_Recipient_Name}
              </p>
              <p>
                支払い期限：{orderProcess.data.order.Meta.AozoraNet_Expires}
              </p>
            </>
          )}
          {orderProcess.data.order.Status === "pending" && (
            <>
              <p className={styles["payment-stripe-title"]}>
                {t("order_aozoranet_title")}
              </p>
              <p className={styles["payment-stripe-title"]}>
                {t("order_aozoranet_text")}
              </p>
              <div className={styles["payment-btns"]}>
                <Button
                  onClick={() => {
                    submitAozoraNet();
                  }}
                  disabled={loading}
                  iconPosition="noting"
                >
                  {t("order_aozoranet_btn")}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      <Toast duration={5000} />
    </>
  );
};

export default AozoraNetWrapper;
