import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import InnerLayout from "components/Layout/InnerLayout";
import DisplayFanclub from "components/Cms/DisplayFanclub";
import NotFound from "components/NotFound/NotFound";

// style
import styles from "./FanclubGallery.module.scss";

// constant
import { IMG_VARIATION_BLOG_DETAIL } from "const/const";

const FanclubGalleryDetail = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [notFound, setNotFound] = useState(false);

  const [article] = useRest(
    `Membership/@fanclub:loadArticleSlug`,
    { slug: slug, image_variation: IMG_VARIATION_BLOG_DETAIL },
    true
  );

  useEffect(() => {
    if (article === false) {
      setNotFound(true);
    } else if (article !== null) {
      setNotFound(false);
    }
  }, [article]);

  return (
    <>
      {notFound && <NotFound backType="back" />}
      {!notFound && (
        <InnerLayout
          headType="fanclub"
          titleVariant="body1"
          title={t("common_gallery")}
          content="detail"
        >
          <div className={`${styles["content-wrapper"]}`}>
            <div className={styles["content-container"]}>
              <DisplayFanclub article={article} />
            </div>
          </div>
        </InnerLayout>
      )}
    </>
  );
};

export default FanclubGalleryDetail;
