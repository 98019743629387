import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

// component
import Helmet from "components/Helmet/Helmet";
import Button from "components/Btns/Btn";

// utils
import { buildDate } from "utils/BuildDate";

// styles
import styles from "./Event.module.scss";

// img
import iconCalendar from "assets/img/icon-calendar.png";
import iconStar from "assets/img/icon-star.png";
import imgDefault from "assets/img/img-event-default.png";

// const
import { IMG_VARIATION_INFOMATION_DETAIL } from "const/const";

const DisplayEvent = ({ article }) => {
  const { t } = useTranslation();
  const articleDetail = article?.data?.Content_Cms_Entry_Data;
  const [eventTickets, setEventTickets] = useState(null);
  const [eventPrice, setEventPrice] = useState(null);

  const [streamingLinkShow, setStremingLinkShow] = useState(false);

  useEffect(() => {
    setStremingLinkShow(
      article.data.Planner_Event.Seat_Types.indexOf("stream") === -1
        ? false
        : true
    );

    // ticket & price

    const sortTicket = _.sortBy(article.data.Planner_Event.Seats, (sortVal) => {
      return parseInt(sortVal.Price.value_disp);
    });

    const sortByArray = _.filter(sortTicket, (val) => {
      if (val.Price.value_disp !== "0") {
        return val;
      }
    });

    setEventTickets(sortByArray);

    const prices = _.map(sortByArray, (val) => {
      if (val.Price.value_disp !== "0") {
        return val.Price.display;
      }
    });

    setEventPrice(prices);

    return () => {
      setEventPrice(null);
      setEventTickets(null);
      setStremingLinkShow(false);
    };
  }, [article]);

  return (
    <>
      <Helmet>
        <title>{`${article.data.Label} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${article.data.Label} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${article.data.Label} - ${t("common_title")}`}
        />
      </Helmet>
      <div className={styles["event-head"]}>
        <h1 className={styles["event-head-title"]}>{articleDetail.Title}</h1>
        {articleDetail.Short_Contents !== "" && (
          <p className={styles["event-head-sub"]}>
            {articleDetail.Short_Contents}
          </p>
        )}
        <p className={styles["event-head-date"]}>
          <img
            src={iconCalendar}
            alt={buildDate(
              parseInt(
                article.data.Planner_Event__ === null
                  ? article.data.Date.unixms
                  : article.data.Planner_Event.Event_Date.unixms
              ),
              "YYYY年MM月DD日 HH:mm～"
            )}
          />
          {buildDate(
            parseInt(
              article.data.Planner_Event__ === null
                ? article.data.Date.unixms
                : article.data.Planner_Event.Event_Date.unixms
            ),
            "YYYY年MM月DD日 HH:mm～"
          )}
        </p>
        {eventPrice !== null && eventPrice.length !== 0 && (
          <>
            <p className={styles["event-head-price"]}>
              <img src={iconStar} alt="Price" />
              {eventPrice.length === 1 && <>{eventPrice[0]}</>}
              {eventPrice.length > 1 && (
                <>
                  {eventPrice[0]} ~ {eventPrice.slice(-1)[0]}
                </>
              )}
            </p>
          </>
        )}
      </div>
      {streamingLinkShow && (
        <Button
          className={styles["event-btn-full"]}
          to={`/live/${article.data.Planner_Schedule__}/streaming`}
        >
          {t("common_streaming_to")}
        </Button>
      )}
      <div className={styles["event-content"]}>
        {articleDetail.Drive_Item__ !== null && (
          <div className={styles["event-content-img"]}>
            <img
              src={
                articleDetail.Drive_Item.Media_Image[
                  IMG_VARIATION_INFOMATION_DETAIL
                ]
              }
              alt={article.data.Label}
            />
          </div>
        )}
        {articleDetail.Drive_Item__ === null && (
          <div className={styles["event-content-img"]}>
            <img src={imgDefault} alt={article.data.Label} />
          </div>
        )}
        {articleDetail.Contents !== "" && (
          <div className={styles["event-content-body"]}>
            <div
              className={styles["cms-contents"]}
              dangerouslySetInnerHTML={{
                __html: articleDetail.Contents,
              }}
            />
            <p className={styles["event-content-copy"]}>{t("common_title")}</p>
          </div>
        )}
      </div>
      {streamingLinkShow && (
        <Button
          className={styles["event-btn-full"]}
          to={`/live/${article.data.Planner_Schedule__}/streaming`}
        >
          {t("common_streaming_to")}
        </Button>
      )}
      {article.data.Planner_Event__ !== null &&
        article.data.Planner_Event.Status === "reserve_open" &&
        eventTickets !== null && (
          <div className={styles["event-btns-container"]}>
            {eventTickets.map((item, key) => {
              if (item.Price.value_disp !== "0") {
                return (
                  <div className={styles["event-btns-list"]} key={key}>
                    <dl className={styles["event-btns-list-group"]}>
                      <dt className={styles["event-btns-list-title"]}>
                        {item.Seat_Name}
                      </dt>
                      <dd className={styles["event-btns-list-price"]}>
                        価格：
                        <span
                          className={styles["event-btns-list-price-strong"]}
                        >
                          {item.Price.display_short}
                        </span>
                      </dd>
                    </dl>
                    <div className={styles["event-btns-list-title-btn"]}>
                      <Button
                        to={`/reserve/${article.data.Planner_Schedule__}?seat=${item.Planner_Event_Seat__}`}
                      >
                        {t("event_buy_btn")}
                      </Button>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
      <div className={styles["event-how"]}>
        <h2 className={styles["event-how-title"]}>{t("event_how_title")}</h2>
        <ul className={styles["event-how-list"]}>
          <li className={styles["event-how-item"]}>
            <dl>
              <dt>{t("event_how_credit_title")}</dt>
              <dd>{t("event_how_credit_text")}</dd>
            </dl>
          </li>
          <li className={styles["event-how-item"]}>
            <dl>
              <dt>{t("event_how_prepaid_title")}</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: t("event_how_prepaid_text"),
                }}
              />
            </dl>
          </li>
          <li className={styles["event-how-item"]}>
            <dl>
              <dt>{t("event_how_vandle_title")}</dt>
              <dd
                dangerouslySetInnerHTML={{ __html: t("event_how_vandle_text") }}
              />
            </dl>
          </li>
          <li className={styles["event-how-item"]}>
            <dl>
              <dt>{t("event_how_transfer_title")}</dt>
              <dd
                dangerouslySetInnerHTML={{
                  __html: t("event_how_transfer_text"),
                }}
              />
            </dl>
          </li>
        </ul>
        <p
          className={styles["event-how-text"]}
          dangerouslySetInnerHTML={{ __html: t("event_how_text") }}
        />
      </div>
      <div className={styles["event-attention"]}>
        <h2 className={styles["event-attention-title"]}>
          {t("event_attention_title")}
        </h2>
        <ul className={styles["event-attention-list"]}>
          <li className={styles["event-attention-item"]}>
            {t("event_attention_text_01")}
          </li>
          <li className={styles["event-attention-item"]}>
            {t("event_attention_text_02")}
          </li>
          <li className={styles["event-attention-item"]}>
            {t("event_attention_text_03")}
          </li>
        </ul>
      </div>
    </>
  );
};

export default DisplayEvent;
