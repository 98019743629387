import React from "react";

// style
import styles from "./Logo.module.scss";

// img
import imgLogo from "assets/img/img-logo.png";

const Logo = () => {
  return (
    <div className={styles["logo"]}>
      <img className={styles["logo-img"]} src={imgLogo} />
    </div>
  );
};

export default Logo;
