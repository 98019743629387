import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { rest } from "@karpeleslab/klbfw";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";

// components
import Sns from "components/Sns/Sns";

// style
import styles from "../Header.module.scss";

// img
import iconRight from "assets/img/icon-right.png";
import clsx from "clsx";

const Nav = ({ menuShow, setMenuShow, fanclub, setFanclub }) => {
  const { t } = useTranslation();
  const [user] = useRest("User:get");
  const userRefresh = useRestRefresh("User:get");
  const history = useHistory();
  const menuLayout = useRef();

  const hideMenu = () => {
    setMenuShow(false);
    setFanclub(false);
  };

  const logOut = () => {
    rest("User/@:logout", "POST").then(() => {
      userRefresh();
      hideMenu();
      history.push("/login");
    });
  };

  useEffect(() => {
    if (menuLayout.current === void 0) return;

    if (menuShow) {
      disableBodyScroll(menuLayout.current);
    } else {
      enableBodyScroll(menuLayout.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [menuShow]);

  return (
    <>
      <div
        className={clsx(styles["header-menu-overlay"], {
          [styles["header-menu-overlay-active"]]: menuShow,
        })}
      >
        <div ref={menuLayout} className={styles["header-menu-overlay-inner"]}>
          <ul
            className={clsx(styles["header-menu-main"], {
              [styles["header-menu-main-active"]]: fanclub,
            })}
          >
            <li>
              <Link onClick={() => hideMenu()} to="/">
                {t("common_home")}
              </Link>
            </li>
            <li>
              <Link onClick={() => hideMenu()} to="/discography">
                {t("common_disco")}
              </Link>
            </li>
            <li>
              <Link onClick={() => hideMenu()} to="/gallery">
                {t("common_gallery")}
              </Link>
            </li>
            <li>
              <Link onClick={() => hideMenu()} to="/live">
                {t("common_live")}
              </Link>
            </li>
            <li>
              <Link onClick={() => hideMenu()} to="/news">
                {t("common_news")}
              </Link>
            </li>
            <li
              onClick={() => {
                setFanclub(!fanclub);
              }}
              className={styles["header-menu-fanclub"]}
            >
              {t("common_fanclub")}
              <img
                className={clsx(styles["header-menu-fanclub-icon"], {
                  [styles["header-menu-fanclub-icon-active"]]: fanclub,
                })}
                alt={t("common_fanclub")}
                src={iconRight}
              />
            </li>
            <li>
              <Link onClick={() => hideMenu()} to="/shop">
                {t("common_shop")}
              </Link>
            </li>
            <li>
              <Link onClick={() => hideMenu()} to="/guideline">
                {t("common_guideline")}
              </Link>
            </li>
            <li>
              <Link onClick={() => hideMenu()} to="/contact">
                {t("common_contact")}
              </Link>
            </li>
            {user?.data === null && (
              <>
                <li>
                  <Link onClick={() => hideMenu()} to="/login">
                    {t("common_login")}
                  </Link>
                </li>
              </>
            )}
            {user?.data !== null && (
              <>
                <li>
                  <Link onClick={() => hideMenu()} to="/mypage">
                    {t("common_mypage")}
                  </Link>
                </li>
                <li>
                  <span onClick={() => logOut()}>{t("common_logout")}</span>
                </li>
              </>
            )}
          </ul>
          <div className={styles["header-menu-add"]}>
            {!fanclub && (
              <div className={styles["header-menu-add-sns"]}>
                <Sns />
              </div>
            )}

            {fanclub && (
              <ul className={styles["header-menu-sub"]}>
                <li>
                  <Link onClick={() => hideMenu()} to="/fanclub">
                    {t("common_fanclub_top")}
                  </Link>
                </li>
                <li>
                  <span>{t("common_only")}</span>
                  <Link onClick={() => hideMenu()} to="/fanclub/news">
                    {t("common_news_fanclub")}
                  </Link>
                </li>
                <li>
                  <Link onClick={() => hideMenu()} to="/fanclub/live">
                    {t("common_video")}
                  </Link>
                </li>
                <li>
                  <Link onClick={() => hideMenu()} to="/fanclub/blog">
                    {t("common_blog")}
                  </Link>
                </li>
                <li>
                  <Link onClick={() => hideMenu()} to="/fanclub/gallery">
                    {t("common_gallery")}
                  </Link>
                </li>
                <li>
                  <Link onClick={() => hideMenu()} to="/fanclub/mewbox">
                    {t("common_box")}
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
