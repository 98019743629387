import React, { useEffect, useState } from "react";
import { rest } from "@karpeleslab/klbfw";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { error, success } from "components/Toast/Toast";

// component
import LoadingElements from "components/Loading/LoadingElements";
import StripeForm from "./StripeForm";

// styles
import styles from "./Payment.module.scss";

// const
import { BILLING_LABEL } from "const/const";

const StripeWrapper = ({
  type = "mypage",
  billing = null,
  orderProcess = null,
  setEdit = false,
  fName = "",
  lName = "",
  zip = "",
  country = "",
  address = "",
  city = "",
  province = "",
  phone = "",
  formEnabled = false,
  load = false,
  setLoad = null,
  cartRefresh = null,
  billingRefresh = null,
  gender = null,
  birth = null,
  selectedPlan = null,
  setSubscribeComp = null,
  setOrderComp = null,
}) => {
  // stripe
  const [stripeKey, setStripeKey] = useState(null);
  const [stripeOptions, setStripeOptions] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  // stripe
  useEffect(() => {
    if (orderProcess === null) {
      rest("Order/Payment:methodInfo", "POST", { method: "Stripe" }).then(
        (data) => {
          setStripeKey(data.data.Fields.cc_token.attributes.key);
          setStripeOptions(data.data.Fields.cc_token.attributes.options);
        }
      );
    } else {
      setStripeKey(
        orderProcess.data.methods.Stripe.fields.cc_token.attributes.key
      );
      setStripeOptions(
        orderProcess.data.methods.Stripe.fields.cc_token.attributes.options
      );
    }
  }, []);

  useEffect(() => {
    if (stripeKey !== null && stripeOptions !== null) {
      setStripePromise(
        loadStripe(stripeKey, {
          stripeAccount: stripeOptions.stripe_account,
        })
      );
    }
  }, [stripeKey, stripeOptions]);

  const stripeError = (message) => {
    error(message);
  };

  // update
  const updateSubmit = (token) => {
    setLoad(true);
    rest(
      `User/Billing/Method/${billing.data[0].Methods[0].User_Billing_Method__}:change`,
      "POST",
      {
        cc_token: token.token.id,
        method: "Stripe",
      }
    )
      .then(() => {
        success("toast_success_text", true, {
          position: "top-center",
          autoClose: 1500,
          onClose: () => {
            billingRefresh();
          },
        });
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  // mypage
  const mypageSubmit = (token) => {
    setLoad(true);
    rest("User/@/Location", "POST", {
      First_Name: fName,
      Last_Name: lName,
      Zip: zip,
      Country__: country,
      Address: address,
      City: city,
      Province: province,
      Contact_Phone: phone,
    })
      .then((data) => {
        rest("User/@/Billing:create", "POST", {
          Label: BILLING_LABEL,
          User_Location__: data.data.User_Location__,
          cc_token: token.token.id,
          method: "Stripe",
        })
          .then(() => {
            success("toast_billing_save", true, {
              position: "top-center",
              autoClose: 1500,
              onClose: () => {
                billingRefresh();
              },
            });
          })
          .catch((err) => {
            setLoad(false);
            error(err.message, false, {
              position: "top-center",
              autoClose: 3000,
            });
          });
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  // order
  const orderSubmit = (token) => {
    setLoad(true);
    rest(`Order/${orderProcess.data.order.Order__}:process`, "POST", {
      method: orderProcess.data.methods.Stripe.method,
      session: orderProcess.data.methods.Stripe.session,
      cc_token: token.token.id,
    })
      .then(() => {
        setLoad(false);
        cartRefresh();
        setOrderComp(true);
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  // subscribe
  const subscribeSubmit = (token) => {
    setLoad(true);

    const profile = {
      Gender: gender,
      Birthdate: birth,
    };

    rest("User/@/Profile", "PATCH", profile)
      .then(() => {
        // location
        rest("User/@/Location", "POST", {
          First_Name: fName,
          Last_Name: lName,
          Zip: zip,
          Country__: country,
          Address: address,
          City: city,
          Province: province,
          Contact_Phone: phone,
        })
          .then((data) => {
            // Billing
            rest("User/@/Billing:create", "POST", {
              Label: BILLING_LABEL,
              User_Location__: data.data.User_Location__,
              cc_token: token.token.id,
              method: "Stripe",
            })
              .then((data) => {
                rest(
                  `Membership/Plan/${selectedPlan.Membership_Plan__}:subscribe`,
                  "POST",
                  {
                    User_Billing__: data.data.User_Billing__,
                  }
                )
                  .then(() => {
                    setSubscribeComp(true);
                  })
                  .catch((err) => {
                    setLoad(false);
                    error(err.message, false, {
                      position: "top-center",
                      autoClose: 3000,
                    });
                  });
              })
              .catch((err) => {
                setLoad(false);
                error(err.message, false, {
                  position: "top-center",
                  autoClose: 3000,
                });
              });
          })
          .catch((err) => {
            setLoad(false);
            error(err.message, false, {
              position: "top-center",
              autoClose: 3000,
            });
          });
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  const settingPropsObject = () => {
    let commonObj = {
      stripeError: stripeError,
      type: type,
      load: load,
      setLoad: setLoad,
    };

    switch (type) {
      case "withBilling":
        commonObj["submitForm"] = mypageSubmit;
        commonObj["formEnabled"] = formEnabled;
        break;
      case "update":
        commonObj["submitForm"] = updateSubmit;
        commonObj["setEdit"] = setEdit;
        break;
      case "order":
        commonObj["submitForm"] = orderSubmit;
        break;
      case "fanclub":
        commonObj["submitForm"] = subscribeSubmit;
        break;
      default:
        break;
    }

    return commonObj;
  };

  return (
    <>
      {stripePromise === null && (
        <LoadingElements className={styles["payment-load-elements"]} />
      )}
      {stripePromise !== null && (
        <>
          <Elements stripe={stripePromise}>
            <StripeForm {...settingPropsObject()} />
          </Elements>
        </>
      )}
    </>
  );
};

export default StripeWrapper;
