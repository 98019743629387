import React from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { useHistory } from "react-router-dom";

// material ui
import { Typography } from "@material-ui/core";

// component
import TextLink from "components/Link/TextLink";

// style
import styles from "../Header.module.scss";

// img
import Logo from "assets/img/bg-fanclub-logo.png";
import clsx from "clsx";

const HeaderFanclub = ({ title, titleVariant = "h1", content = null }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [user] = useRest("User:get");
  const userRefresh = useRestRefresh("User:get");

  const logOut = () => {
    rest("User/@:logout", "POST").then(() => {
      userRefresh();
    });
  };

  return (
    <>
      <header
        className={styles["header-fanclub"]}
        style={{
          backgroundImage: `url(${Logo})`,
        }}
      >
        <span className={styles["header-fanclub-title"]}>
          {t("common_fanclub")}
        </span>
        <div className={styles["header-fanclub-user"]}>
          <p className={styles["header-fanclub-user-text"]}>
            {t("fanclub_user_welcom", {
              name: user?.data.Profile.Display_Name,
            })}
          </p>
          <p
            className={styles["header-fanclub-user-logout"]}
            onClick={() => {
              logOut();
            }}
          >
            {t("common_logout")}
          </p>
        </div>
      </header>
      <div
        className={clsx(styles["header-fanclub-page"], {
          [styles["header-fanclub-page-detail"]]: content !== null,
        })}
      >
        <div>
          <Typography
            className={styles["header-fanclub-page-title"]}
            variant={titleVariant}
          >
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Typography>
          <p className={styles["header-fanclub-page-sub-title"]}>
            {t("common_only")}
          </p>
        </div>
        {content !== null && (
          <TextLink
            className={styles["header-fanclub-back"]}
            onClick={() => {
              history.goBack();
            }}
            text_token="common_btn_back_list"
            type="back"
          />
        )}
      </div>
    </>
  );
};

export default HeaderFanclub;
