import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

// material ui
import { Tabs, Tab } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import History from "./section/History";
import User from "./section/User";
import Profile from "./section/Profile";
import Password from "./section/Password";
import Billing from "./section/Billing";
import MemberShip from "./section/MemberShip";

// styles
import styles from "./MyPage.module.scss";

const MyPage = ({ type }) => {
  const [tabArray] = useState([
    "history",
    "user",
    "profile",
    "password",
    "billing",
    "membership",
  ]);
  const location = useLocation();
  const [tabVal, setTabVal] = useState(tabArray.indexOf(type));
  const { t } = useTranslation();

  useEffect(() => {
    setTabVal(tabArray.indexOf(type));
  }, [location]);

  const buildSection = () => {
    switch (type) {
      case "history":
        return <History />;
      case "user":
        return <User />;
      case "profile":
        return <Profile />;
      case "password":
        return <Password />;
      case "billing":
        return <Billing />;
      case "membership":
        return <MemberShip />;
      default:
        return <History />;
    }
  };

  return (
    <InnerLayout headType="lower" title={t("mypage_title")}>
      <Helmet>
        <title>{`${t("mypage_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("mypage_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("mypage_title")} - ${t("common_title")}`}
        />
      </Helmet>
      <div className={`${styles["content-wrapper"]}`}>
        <div className={styles["content-container"]}>
          <div className={styles["mypage-head"]}>
            <Tabs
              value={tabVal}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabArray.map((val, index) => {
                if (index === 0) {
                  return (
                    <Tab
                      key={index}
                      className={styles["mypage-head-item"]}
                      label={t(`maypage_tab_${val}`)}
                      component={Link}
                      to="/mypage"
                    />
                  );
                }
                return (
                  <Tab
                    key={index}
                    className={styles["mypage-head-item"]}
                    label={t(`maypage_tab_${val}`)}
                    component={Link}
                    to={`/mypage/${val}`}
                  />
                );
              })}
            </Tabs>
          </div>
          {buildSection()}
        </div>
      </div>
    </InnerLayout>
  );
};

export default MyPage;
