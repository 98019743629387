import React, { useEffect, useState, useCallback, useRef } from "react";
import { rest } from "@karpeleslab/klbfw";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { useLocation } from "react-router-dom";

// icon

// component

// img
import AvatarImg from "assets/img/img-avatar-default.png";

// style
import styles from "../Chat.module.scss";

const Chat = ({ article = null }) => {
  const location = useLocation();
  const chatRef = useRef(null);
  const chatHeadRef = useRef(null);
  const [chatLog, setChatLog] = useState([]);
  const [type, setType] = useState(null);
  const params = new URLSearchParams(location.search.replace("?", ""));

  useEffect(() => {
    if (params.get("superchatonly") === "true") {
      setType("superchatonly");
    } else {
      setType(null);
    }
  }, [location]);

  // superchat
  const [superChat, setSuperChat] = useState([]);

  const updateChat = useCallback(() => {
    let query = {
      index: 0,
    };

    if (chatLog.length !== 0) {
      query.index = chatLog.slice(-1)[0].Index;
    }

    rest(
      `Planner/Event/${article.data.Planner_Event__}/Chat:poll`,
      "POST",
      query
    )
      .then((res) => {
        setChatLog([...chatLog, ...res.data]);
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      })
      .catch(() => {});
  }, [chatLog, article]);

  useEffect(() => {
    if (article !== null) {
      updateChat();
    }
  }, [updateChat]);

  useEffect(() => {
    const superChatArray = _.filter(chatLog, function (o) {
      return o.Type === "superchat";
    });

    const timeCheckSuperChatArray = _.filter(superChatArray, (val) => {
      if (!timeCheck(val)) {
        return val;
      }
    });

    const sortSuperChat = _.sortBy(timeCheckSuperChatArray, [
      function (o) {
        return parseInt(o.Amount.value);
      },
    ]).reverse();

    setSuperChat(sortSuperChat);
  }, [chatLog]);

  const buildImg = useCallback((val) => {
    if (val.Profile.Media_Image) {
      return val.Profile.Media_Image.Url;
    } else {
      return AvatarImg;
    }
  }, []);

  const timeCheck = (val) => {
    const deadlineTime = moment(parseInt(val.Date.unixms)).add(5, "minutes");
    const currentTime = moment();
    const afterCheck = currentTime.isAfter(deadlineTime);

    return afterCheck;
  };

  return (
    <>
      <div className={styles["chat"]}>
        <div ref={chatHeadRef} className={styles["chat-head"]}>
          {superChat.length !== 0 && (
            <div className={styles["chat-super"]}>
              {superChat.map((val, index) => {
                return (
                  <>
                    <div
                      data-tip={val.Profile.Display_Name}
                      className={clsx(styles["chat-super-item"], {
                        [styles["chat-super-item--hight"]]:
                          parseInt(val.Amount.value_disp) >= 10000,
                        [styles["chat-super-item--low"]]:
                          parseInt(val.Amount.value_disp) < 10000,
                      })}
                      key={index}
                    >
                      <figure className={styles["chat-super-item-img"]}>
                        <img
                          src={buildImg(val)}
                          alt={val.Profile.Display_Name}
                        />
                      </figure>
                      <span className={styles["chat-super-item-amount"]}>
                        {val.Amount.display_short}
                      </span>
                    </div>
                    <ReactTooltip key={val.Index} />
                  </>
                );
              })}
            </div>
          )}
        </div>
        <div
          ref={chatRef}
          className={clsx(styles["chat-body"], {
            [styles["chat-body-only"]]: type === "superchatonly",
          })}
        >
          {chatLog.map((val, index) => {
            return (
              <>
                {val.Type === "text" && type !== "superchatonly" && (
                  <div className={styles["chat-body-item"]} key={index}>
                    <figure className={styles["chat-body-item-img"]}>
                      <img src={buildImg(val)} alt={val.Profile.Display_Name} />
                    </figure>
                    <div className={styles["chat-body-item-texts"]}>
                      <span className={styles["chat-body-item-texts-date"]}>
                        {moment(parseInt(val.Date.unixms)).format("HH:mm")}
                      </span>
                      <span className={styles["chat-body-item-texts-name"]}>
                        {val.Profile.Display_Name}
                      </span>
                      <span>{val.Message}</span>
                    </div>
                  </div>
                )}
                {val.Type === "superchat" && (
                  <div className={styles["chat-body-item-super"]} key={index}>
                    <div
                      className={clsx(styles["chat-body-item-super-head"], {
                        [styles["chat-body-item-super-head--hight"]]:
                          parseInt(val.Amount.value_disp) >= 10000,
                        [styles["chat-body-item-super-head--low"]]:
                          parseInt(val.Amount.value_disp) < 10000,
                      })}
                    >
                      <figure className={styles["chat-body-item-super-img"]}>
                        <img
                          src={buildImg(val)}
                          alt={val.Profile.Display_Name}
                        />
                      </figure>
                      <div className={styles["chat-body-item-super-group"]}>
                        <p
                          className={styles["chat-body-item-super-group-name"]}
                        >
                          {val.Profile.Display_Name}
                        </p>
                        <p
                          className={
                            styles["chat-body-item-super-group-amount"]
                          }
                        >
                          {val.Amount.display}
                        </p>
                      </div>
                    </div>
                    <div
                      className={clsx(styles["chat-body-item-super-text"], {
                        [styles["chat-body-item-super-text--hight"]]:
                          parseInt(val.Amount.value_disp) >= 10000,
                        [styles["chat-body-item-super-text--low"]]:
                          parseInt(val.Amount.value_disp) < 10000,
                      })}
                    >
                      {val.Message}
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Chat;
