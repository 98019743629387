import React from "react";

// component
import InnerLayout from "components/Layout/InnerLayout";
import TextLink from "components/Link/TextLink";

// style
import styles from "./NotFound.module.scss";

// img
import bgNotfound from "assets/img/bg-notfound.png";

const NotFound = () => {
  return (
    <InnerLayout headType="notFound" titleVariant="body1">
      <div className={styles["notFound-container"]}>
        <h1 className={styles["notFound-title"]}>
          <span>404</span>PAGE NOT FOUND
        </h1>
        <img
          className={styles["notFound-img"]}
          src={bgNotfound}
          alt="PAGE NOT FOUND"
        />
        <TextLink path="/" text_token="common_btn_top" />
      </div>
    </InnerLayout>
  );
};

export default NotFound;
