import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import Loading from "components/Loading/Loading";
import TextList from "components/List/TextList";
import TextLink from "components/Link/TextLink";

// utils

// style
import styles from "../Fanclub.module.scss";

// constant
import { FANCLUB_NEWS_PARAMS } from "const/const";

const News = () => {
  const { t } = useTranslation();

  const [news] = useRest("Membership/@fanclub:cms_search", {
    ...FANCLUB_NEWS_PARAMS,
    ...{ page_no: 0 },
    ...{ results_per_page: 3 },
  });

  return (
    <>
      <h2 className={styles["fanclub-title"]}>{t("common_news_fanclub")}</h2>
      {news === null && <Loading />}
      {news !== null && (
        <TextList type="dark" vals={news?.data.data} path="fanclub/news" />
      )}

      <TextLink type="dark" path="/fanclub/news" />
    </>
  );
};

export default News;
