import React, { useState, useEffect } from "react";
import { rest, Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

// component
import Helmet from "components/Helmet/Helmet";
import InnerLayout from "components/Layout/InnerLayout";
import LoadingOverLay from "components/Loading/LoadingOverLay";
import { error } from "components/Toast/Toast";
import InputForm from "components/Forms/Input";
import Button from "components/Btns/Btn";

// utile

// style
import styles from "./PasswordReset.module.scss";

// img

// const

const PasswordReset = () => {
  const { t } = useTranslation();
  const { login } = useParams();
  const [loading, setloading] = useState(false);
  const [formEnabled, setFormEnabled] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConf, setNewPasswordConf] = useState("");
  const [comp, setComp] = useState(false);

  const resetPost = (e) => {
    e.preventDefault();

    setloading(true);
    rest("User:recover_password", "POST", {
      login: login,
      key: Get("k"),
      p1: newPassword,
      p2: newPasswordConf,
    })
      .then(() => {
        setloading(false);
        setComp(true);
      })
      .catch((err) => {
        setloading(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (
      !newPassword.trim() ||
      !newPasswordConf.trim() ||
      newPasswordConf.length < 6 ||
      newPassword.length < 6
    ) {
      setFormEnabled(false);
      return;
    }
    setFormEnabled(true);
  }, [newPassword, newPasswordConf]);

  return (
    <InnerLayout headType="lower" title={t("common_reset_password")}>
      <Helmet>
        <title>{`${t("common_reset_password")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("common_reset_password")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("common_reset_password")} - ${t("common_title")}`}
        />
      </Helmet>
      <div className={`${styles["content-wrapper"]}`}>
        <div className={styles["content-container"]}>
          <div className={styles["forgot-container"]}>
            {comp && (
              <>
                <p className={styles["forgot-text"]}>{t("reset_text_comp")}</p>
                <div className={styles["forgot-btn-group"]}>
                  <Button to="/login">{t("common_login")}</Button>
                </div>
              </>
            )}
            {!comp && (
              <>
                <p
                  className={styles["forgot-text"]}
                  dangerouslySetInnerHTML={{ __html: t("reset_text") }}
                ></p>
                <form onSubmit={(e) => resetPost(e)}>
                  {newPassword !== newPasswordConf && (
                    <p className={styles["forgot-error"]}>{t("reset_pass")}</p>
                  )}
                  {newPasswordConf.length > 0 &&
                    newPassword.length > 0 &&
                    newPasswordConf.length < 6 &&
                    newPassword.length < 6 && (
                      <p className={styles["forgot-error"]}>
                        {t("reset_error_length")}
                      </p>
                    )}
                  <InputForm
                    type="password"
                    value={newPassword}
                    name="user-password"
                    label={t("reset_label_new_password")}
                    placeholder={t("input_field_placeholder", {
                      text: t("reset_label_new_password"),
                    })}
                    handleChange={(event) => {
                      setNewPassword(event.target.value);
                    }}
                    disabled={loading}
                  />
                  <InputForm
                    type="password"
                    value={newPasswordConf}
                    name="user-password-conf"
                    label={t("reset_label_new_password_confirm")}
                    placeholder={t("input_field_placeholder", {
                      text: t("reset_label_new_password_confirm"),
                    })}
                    handleChange={(event) => {
                      setNewPasswordConf(event.target.value);
                    }}
                    disabled={loading}
                  />
                  <div className={styles["forgot-btn-group"]}>
                    <Button
                      btnTypes="submit"
                      disabled={
                        !formEnabled ||
                        loading ||
                        newPassword !== newPasswordConf
                      }
                    >
                      {t("fotgot_submit")}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
      {loading && <LoadingOverLay />}
    </InnerLayout>
  );
};

export default PasswordReset;
