import React from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import Slider from "react-slick";

// components
import PlanCard from "components/Fanclub/PlanCard";

// style
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Plan = () => {
  const [plan] = useRest("Membership/@fanclub");

  const SlickOptions = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: false,
    arrows: false,
  };

  return (
    <>
      {plan !== null && (
        <Slider {...SlickOptions}>
          {plan.data.Membership_Plan.map((val, index) => {
            return <PlanCard val={val} key={index} />;
          })}
        </Slider>
      )}
    </>
  );
};

export default Plan;
