import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// img
import IconArrowRight from "assets/img/icon-arrow-right.png";
import IconArrowRightDark from "assets/img/icon-arrow-right-dark.png";
import IconArrowLeft from "assets/img/icon-arrow-left.png";

// style
import styles from "./Link.module.scss";
import clsx from "clsx";

const TextLink = ({
  path = "/",
  text_token = "common_view_all",
  onClick = null,
  type = null,
  className = null,
}) => {
  const { t } = useTranslation();

  const imgSet = () => {
    switch (type) {
      case "back":
        return <img src={IconArrowLeft} alt={t(text_token)} />;
      case "dark":
        return <img src={IconArrowRightDark} alt={t(text_token)} />;
      default:
        return <img src={IconArrowRight} alt={t(text_token)} />;
    }
  };

  return (
    <p
      className={clsx(styles["link-text"], {
        [className]: className !== null,
      })}
    >
      {onClick !== null && (
        <span
          onClick={onClick}
          className={clsx(styles["link-text-item"], {
            [styles["link-text-item-back"]]: type === "back",
            [styles["link-text-item-dark"]]: type === "dark",
          })}
        >
          {t(text_token)}
          {imgSet()}
        </span>
      )}
      {onClick === null && (
        <Link
          to={path}
          className={clsx(styles["link-text-item"], {
            [styles["link-text-item-back"]]: type === "back",
            [styles["link-text-item-dark"]]: type === "dark",
          })}
        >
          {t(text_token)}
          {imgSet()}
        </Link>
      )}
    </p>
  );
};

export default TextLink;
