import React from "react";
import { useTranslation } from "react-i18next";

// component
import PlanMini from "components/Fanclub/PlanMini";

// utils

// style
import styles from "../Fanclub.module.scss";

const About = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className={styles["fanclub-about-title"]}>
        {t("fanclub_abount_title")}
      </h2>
      <p
        className={`${styles["fanclub-benefits-content-text"]} ${styles["fanclub-benefits-content-text-top"]}`}
        dangerouslySetInnerHTML={{
          __html: t("fanclub_benefits_content_text"),
        }}
      />
      <p
        className={styles["fanclub-benefits-content-text-attention"]}
        dangerouslySetInnerHTML={{
          __html: t("fanclub_benefits_content_text_attention"),
        }}
      ></p>
      <PlanMini />
    </>
  );
};

export default About;
