import React from "react";

// component
import NotFoundDisplay from "components/NotFound/NotFound";

const NotFound = () => {
  return (
    <>
      <NotFoundDisplay backType="top" />
    </>
  );
};

export default NotFound;
