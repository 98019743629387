import React, { useEffect } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useParams } from "react-router-dom";

// icon

// component
import Chat from "./section/Chatbody";

// img

// style

// constant
import { IMG_VARIATION_INFOMATION_DETAIL } from "const/const";

const ChatPage = () => {
  const { slug } = useParams();
  const [article] = useRest(
    `Planner/Schedule/${slug}`,
    { image_variation: IMG_VARIATION_INFOMATION_DETAIL },
    true
  );

  useEffect(() => {
    document.body.style.cssText = `${document.body.style.cssText}overflow: hidden;background: rgba(255,255,255,0) !important;`;
    document.documentElement.style.cssText = `${document.documentElement.style.cssText}background: rgba(255,255,255,0) !important;`;
    return () => {
      document.body.style.cssText = "";
      document.documentElement.style.cssText = "";
    };
  }, []);

  return <>{article !== null && <Chat article={article} />}</>;
};

export default ChatPage;
