import React from "react";
import { useHistory } from "react-router-dom";

// icon
import { ArrowBackIos } from "@material-ui/icons";

// style
import styles from "../Header.module.scss";

const HeaderLive = () => {
  const history = useHistory();
  return (
    <header className={styles["header-live"]}>
      <p
        onClick={() => {
          history.goBack();
        }}
        className={styles["header-live-btn"]}
      >
        <ArrowBackIos />
      </p>
    </header>
  );
};

export default HeaderLive;
