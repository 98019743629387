import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import EventList from "components/List/EventList";
import Pagination from "components/Pagination/Pagination";

// style
import styles from "./FanclubLive.module.scss";

// constant
import { LIVE_PARAMS } from "const/const";

const FanclubLive = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pageNum } = useParams();

  //pages
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );

  const [event] = useRest(
    "Planner/@planner/Schedule",
    {
      ...LIVE_PARAMS,
      ...{ page_no: currentPage },
      ...{ Tag: "fanclub:live" },
    },
    true
  );

  useEffect(() => {
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
  }, [location.pathname, pageNum]);

  return (
    <InnerLayout headType="lower" title={t("common_video")}>
      <Helmet>
        <title>{`${t("common_video")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("common_video")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("common_video")} - ${t("common_title")}`}
        />
      </Helmet>
      <div className={`${styles["content-wrapper"]}`}>
        <div className={styles["content-container"]}>
          {event === null && <Loading />}
          {event !== null && (
            <EventList vals={event?.data} path="fanclub/live" />
          )}
          {event !== null && (
            <Pagination
              setCurrentPage={setCurrentPage}
              defaultPage={currentPage}
              path="/live"
              count={event?.paging.page_max}
            />
          )}
        </div>
      </div>
    </InnerLayout>
  );
};

export default FanclubLive;
