import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

// component
import Button from "components/Btns/Btn";

// styles
import styles from "./Payment.module.scss";

// const
import { STRIPE_OPTIONS } from "const/const";

const StripeForm = ({
  submitForm,
  stripeError,
  load,
  setLoad,
  setEdit,
  type = "submit",
  formEnabled,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [strtipeEnabled, setStripeEnabeld] = useState();

  const stripeHandler = (e) => {
    if (e.complete) {
      setStripeEnabeld(true);
      return;
    }

    setStripeEnabeld(false);
  };

  const submitHandler = () => {
    setStripeEnabeld(false);
    setLoad(true);
    const cardElement = elements.getElement(CardElement);

    stripe.createToken(cardElement).then((token) => {
      if (token.token === void 0) {
        setLoad(false);
        stripeError(token.error.message);
        submitForm(token);
      } else {
        submitForm(token);
      }
    });
  };

  const settingPropsObject = () => {
    let commonObj = {
      onClick: submitHandler,
      iconPosition: "noting",
      disabled: load || !strtipeEnabled,
    };

    let styleObj = {
      className: styles["payment-btns"],
    };

    let btnName = t("common_credit_purchase");

    switch (type) {
      case "withBilling":
        commonObj["disabled"] = load || !formEnabled || !strtipeEnabled;
        btnName = t("common_save");
        break;
      case "update":
        styleObj["className"] = styles["payment-btns-group"];
        btnName = t("mypage_payment_update");
        break;
      default:
        break;
    }

    return {
      styles: styleObj,
      button: commonObj,
      btnName: btnName,
    };
  };

  return (
    <>
      <div className={styles["payment-stripe-wrapper"]}>
        <p className={styles["payment-stripe-title"]}>
          {t("mypage_billing_credit")}
          <span> *</span>
        </p>
        <div className={styles["payment-stripe"]}>
          <CardElement onChange={stripeHandler} options={STRIPE_OPTIONS} />
        </div>
      </div>

      <div {...settingPropsObject().styles}>
        {type === "update" && (
          <Button
            onClick={() => {
              setEdit(false);
            }}
            iconPosition="noting"
            type="danger"
          >
            {t("mypage_payment_cancel")}
          </Button>
        )}
        <Button {...settingPropsObject().button}>
          {settingPropsObject().btnName}
        </Button>
      </div>
    </>
  );
};

export default StripeForm;
