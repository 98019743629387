import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { rest } from "@karpeleslab/klbfw";
import { Link } from "react-router-dom";
import { error, success } from "components/Toast/Toast";
import moment from "moment";
import clsx from "clsx";

// material ui
import { Modal, Typography } from "@material-ui/core";

// component
import Loading from "components/Loading/Loading";
import Button from "components/Btns/Btn";

// utils
import { memberIsActive } from "utils/MemberRequired";

// styles
import styles from "../MyPage.module.scss";

//img
import Cat from "assets/img/icon-cat-white.png";
import MemberShipCardBronz from "assets/img/member-card-bronze.png";
import MemberShipCardSilver from "assets/img/member-card-silver.png";
import MemberShipCardGold from "assets/img/member-card-gold.png";

// const

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: "#453059",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
  },
}));

const MemberShip = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [user, setUser] = useRest("User:get");
  const [memberShip, setMemberShip] = useState(null);
  const [memberShipInfo, setMemberShipInfo] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (user !== null && user?.data !== null) {
      rest("Membership/@fanclub/User/@")
        .then((data) => {
          setMemberShip(memberIsActive(data));
          setMemberShipInfo(data);
        })
        .catch(() => {
          setMemberShip(false);
        });
    } else {
      setMemberShip(false);
    }
  }, [user, setMemberShip]);

  const buildNumber = (index) => {
    const currentNum = index;
    const maxNum = 5;
    const diffNum = maxNum - currentNum.length;
    const addNumber = "0";

    if (index.length < maxNum) {
      let buildNum = "";
      for (var i = 0; i < diffNum; i++) {
        buildNum += addNumber;
      }
      return buildNum + currentNum;
    } else {
      return currentNum;
    }
  };

  const unsubscribe = () => {
    rest("Membership/@fanclub/User/@:unsubscribe", "POST")
      .then(() => {
        setOpen(false);
        success("mypage_unsubscribe_success", true, {
          position: "top-center",
          autoClose: 1500,
          onClose: () => {
            setUser();
          },
        });
      })
      .catch((err) => {
        setOpen(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      <div>
        {memberShip === null && memberShipInfo === null && <Loading />}
        {memberShip === false && memberShipInfo === null && (
          <div>
            <div className={clsx(`${styles["member-ship-plan"]}`)}>
              <div className={`${styles["member-ship-plan-mark"]}`}>
                <img src={Cat} alt="" />
              </div>
              <p className={styles["member-ship-plan-invite"]}>
                {t("mypage_membership_invite")}
              </p>
              <div className={styles["member-ship-btn-group"]}>
                <Link to="/subscribe" className={styles["member-ship-btn"]}>
                  {t("mypage_membership_add")}
                </Link>
              </div>
            </div>
          </div>
        )}
        {memberShip !== null && memberShipInfo !== null && (
          <div>
            <div className={styles["member-ship-card"]}>
              {memberShipInfo?.data.Membership_Plan.Catalog_Product[
                "Basic.Name"
              ] === "GOLD" && <img src={MemberShipCardGold} />}
              {memberShipInfo?.data.Membership_Plan.Catalog_Product[
                "Basic.Name"
              ] === "SILVER" && <img src={MemberShipCardSilver} />}
              {memberShipInfo?.data.Membership_Plan.Catalog_Product[
                "Basic.Name"
              ] === "BRONZE" && <img src={MemberShipCardBronz} />}
              <span className={styles["member-ship-card-number"]}>
                No,{buildNumber(memberShipInfo?.data.Index)}
              </span>
            </div>
            <div
              className={clsx(
                `${styles["member-ship-plan"]} ${
                  styles[
                    `member-ship-plan-${memberShipInfo?.data.Membership_Plan.Catalog_Product[
                      "Basic.Name"
                    ].toLowerCase()}`
                  ]
                }`
              )}
            >
              <h3
                className={styles["member-ship-plan-title"]}
                dangerouslySetInnerHTML={{
                  __html: t(
                    `fanclub_plan_${memberShipInfo?.data.Membership_Plan.Catalog_Product[
                      "Basic.Name"
                    ].toLowerCase()}`
                  ),
                }}
              />
              <dl
                className={`${styles["member-ship-plan-benefits"]} ${
                  styles[
                    `member-ship-plan-benefits-${memberShipInfo?.data.Membership_Plan.Catalog_Product[
                      "Basic.Name"
                    ].toLowerCase()}`
                  ]
                }`}
              >
                <dt>{t("mypage_membership_expires")}</dt>
                <dd>
                  {moment(memberShipInfo?.data.Expires.iso).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </dd>
                {memberShipInfo.data.Status === "Paid" && (
                  <dd className={styles["member-ship-plan-benefits-text"]}>
                    {t("mypage_membership_expires_text", {
                      time: moment(memberShipInfo?.data.Expires.iso)
                        .subtract(3, "d")
                        .format("YYYY年MM月DD日"),
                    })}
                  </dd>
                )}
              </dl>

              {moment().isAfter(moment(memberShipInfo?.data.Expires.iso)) && (
                <dl
                  className={`${styles["member-ship-plan-benefits"]} ${
                    styles[
                      `member-ship-plan-benefits-${memberShipInfo?.data.Membership_Plan.Catalog_Product[
                        "Basic.Name"
                      ].toLowerCase()}`
                    ]
                  }`}
                >
                  <dt>{t("mypage_membership_status")}</dt>
                  <dd>{t("mypage_membership_status_name")}</dd>
                </dl>
              )}
              {}
              {moment().isAfter(moment(memberShipInfo?.data.Expires.iso)) && (
                <div className={styles["member-ship-btn-group"]}>
                  <Link to="/subscribe" className={styles["member-ship-btn"]}>
                    {t("mypage_membership_update")}
                  </Link>
                </div>
              )}
              {moment().isBefore(moment(memberShipInfo?.data.Expires.iso)) && (
                <div className={styles["member-ship-btn-group"]}>
                  <Link to="/subscribe" className={styles["member-ship-btn"]}>
                    {t("mypage_membership_change_plan")}
                  </Link>
                  {memberShipInfo.data.Status === "paid" && (
                    <p
                      className={`${styles["member-ship-btn"]} ${styles["member-ship-btn-delete"]}`}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      {t("mypage_membership_withdrawal")}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className={classes.paper}>
          <Typography
            className={styles["member-ship-modal-title"]}
            align="left"
            variant="h6"
            component="h2"
          >
            {t("mypage_membership_withdrawal_title")}
          </Typography>
          <p className={styles["member-ship-modal-text"]}>
            {t("mypage_membership_withdrawal_text")}
          </p>
          <div className={styles["member-ship-modal-btns-group"]}>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              iconPosition="noting"
            >
              {t("mypage_modal_btn_close")}
            </Button>
            <Button
              iconPosition="noting"
              onClick={() => {
                unsubscribe();
              }}
            >
              {t("mypage_membership_withdrawal_btn")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MemberShip;
