import React from "react";
import { useTranslation } from "react-i18next";

// component

// utils

// icon
import { CheckCircleOutline } from "@material-ui/icons";

// style
import styles from "../Fanclub.module.scss";

// img
import imgVideo from "assets/img/img-video.png";
import imgTicket from "assets/img/img-ticket.png";
import imgGallery from "assets/img/img-gallery.png";
import imgGold from "assets/img/icon-plane-gold.png";
import imgSilver from "assets/img/icon-plane-silver.png";
import imgBronze from "assets/img/icon-plane-bronze.png";
import imgBox from "assets/img/img-box.png";

const Benefits = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className={styles["fanclub-benefits-title"]}>
        {t("fanclub_benefits_title")}
      </h2>
      <div className={styles["fanclub-benefits-content-wrapper"]}>
        <div className={styles["fanclub-benefits-content"]}>
          <h3 className={styles["fanclub-benefits-content-title"]}>
            {t("fanclub_benefits_content_title")}
          </h3>
          <span className={styles["fanclub-benefits-content-rank"]}>
            <img src={imgGold} alt={t("fanclub_plan_gold")} />
            <img src={imgSilver} alt={t("fanclub_plan_silver")} />
            <img src={imgBronze} alt={t("fanclub_plan_bronze")} />
          </span>
        </div>
        <h3 className={styles["fanclub-benefits-content-title-sub"]}>
          {t("fanclub_benefits_content_fanclub_content")}
        </h3>
        <div className={styles["fanclub-children"]}>
          <h4 className={styles["fanclub-children-title"]}>
            <CheckCircleOutline />
            {t("common_news_fanclub")}
          </h4>
          <p
            className={styles["fanclub-children-text"]}
            dangerouslySetInnerHTML={{
              __html: t("fanclub_content_news_fanclub_text"),
            }}
          />
        </div>
        <div className={styles["fanclub-children"]}>
          <h4 className={styles["fanclub-children-title"]}>
            <CheckCircleOutline />
            {t("common_video")}
          </h4>
          <p
            className={styles["fanclub-children-text"]}
            dangerouslySetInnerHTML={{
              __html: t("fanclub_content_video_text"),
            }}
          />
          <p className={styles["fanclub-children-img"]}>
            <img src={imgVideo} alt={t("common_video")} />
          </p>
        </div>
        <div className={styles["fanclub-children"]}>
          <h4 className={styles["fanclub-children-title"]}>
            <CheckCircleOutline />
            {t("common_blog")}
          </h4>
          <p className={styles["fanclub-children-text"]}>
            {t("fanclub_content_blog_text")}
          </p>
        </div>
        <div className={styles["fanclub-children"]}>
          <h4 className={styles["fanclub-children-title"]}>
            <CheckCircleOutline />
            {t("common_gallery")}
          </h4>
          <p className={styles["fanclub-children-text"]}>
            {t("fanclub_content_gallery_text")}
          </p>
          <p className={styles["fanclub-children-img"]}>
            <img src={imgGallery} alt={t("common_gallery")} />
          </p>
        </div>
        <div className={styles["fanclub-children"]}>
          <h4 className={styles["fanclub-children-title"]}>
            <CheckCircleOutline />
            {t("common_box")}
          </h4>
          <p
            className={styles["fanclub-children-text"]}
            dangerouslySetInnerHTML={{ __html: t("fanclub_content_box_text") }}
          ></p>
          <p className={styles["fanclub-children-img"]}>
            <img src={imgBox} alt={t("common_box")} />
          </p>
        </div>
      </div>

      <div className={styles["fanclub-benefits-content-wrapper"]}>
        <div className={styles["fanclub-benefits-content"]}>
          <h3 className={styles["fanclub-benefits-content-title"]}>
            {t("fanclub_benefits_live_title")}
          </h3>
          <span className={styles["fanclub-benefits-content-rank"]}>
            <img src={imgGold} alt={t("fanclub_plan_gold")} />
            <img src={imgSilver} alt={t("fanclub_plan_silver")} />
          </span>
        </div>
        <p
          className={styles["fanclub-benefits-content-text-second"]}
          dangerouslySetInnerHTML={{ __html: t("fanclub_benefits_live_text") }}
        ></p>
        <p className={styles["fanclub-children-img"]}>
          <img src={imgTicket} alt={t("fanclub_benefits_live_title")} />
        </p>
      </div>

      <div className={styles["fanclub-benefits-content-wrapper"]}>
        <div className={styles["fanclub-benefits-content"]}>
          <h3 className={styles["fanclub-benefits-content-title"]}>
            {t("fanclub_benefits_anniversary_title")}
          </h3>
          <span className={styles["fanclub-benefits-content-rank"]}>
            <img src={imgGold} alt={t("fanclub_plan_gold")} />
            <img src={imgSilver} alt={t("fanclub_plan_silver")} />
          </span>
        </div>
        <p
          className={styles["fanclub-benefits-content-text-second"]}
          dangerouslySetInnerHTML={{
            __html: t("fanclub_benefits_anniversary_text"),
          }}
        ></p>
      </div>

      <div className={styles["fanclub-benefits-content-wrapper"]}>
        <div className={styles["fanclub-benefits-content"]}>
          <h3
            className={styles["fanclub-benefits-content-title"]}
            dangerouslySetInnerHTML={{
              __html: t("fanclub_benefits_album_title"),
            }}
          ></h3>
          <span className={styles["fanclub-benefits-content-rank"]}>
            <img src={imgGold} alt={t("fanclub_plan_gold")} />
          </span>
        </div>
        <p
          className={styles["fanclub-benefits-content-text-second"]}
          dangerouslySetInnerHTML={{ __html: t("fanclub_benefits_album_text") }}
        />
      </div>

      <div className={styles["fanclub-benefits-content-wrapper"]}>
        <div className={styles["fanclub-benefits-content"]}>
          <h3 className={styles["fanclub-benefits-content-title"]}>
            {t("fanclub_benefits_message_title")}
          </h3>
          <span className={styles["fanclub-benefits-content-rank"]}>
            <img src={imgGold} alt={t("fanclub_plan_gold")} />
          </span>
        </div>
        <p
          className={styles["fanclub-benefits-content-text-second"]}
          dangerouslySetInnerHTML={{
            __html: t("fanclub_benefits_message_text"),
          }}
        />
      </div>

      <div className={styles["fanclub-benefits-content-wrapper"]}>
        <p className={styles["fanclub-benefits-content-attention"]}>
          {t("fanclub_content_etc_text")}
        </p>
      </div>
    </>
  );
};

export default Benefits;
