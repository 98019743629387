import React from "react";
import { useTranslation } from "react-i18next";

// component

// utils

// icon

// style
import styles from "../Fanclub.module.scss";

// img
import imgGold from "assets/img/icon-plane-gold.png";
import imgSilver from "assets/img/icon-plane-silver.png";
import imgBronze from "assets/img/icon-plane-bronze.png";

const PlanList = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className={styles["fanclub-benefits-title"]}>
        {t("fanclub_plane_title")}
      </h2>
      <div className={styles["fanclub-benefits-content-wrapper-unnumber"]}>
        <h3
          dangerouslySetInnerHTML={{
            __html: t("fanclub_benefits_content_title_sub"),
          }}
          className={styles["fanclub-benefits-content-title-sub"]}
        ></h3>
        <div className={styles["fanclub-children"]}>
          <h4 className={styles["fanclub-children-title-sub"]}>
            <img src={imgBronze} alt={t("fanclub_plan_bronze")} />
            {t("fanclub_benefits_content_bronze_title")}
          </h4>
          <p
            className={`${styles["fanclub-children-text"]} ${styles["fanclub-children-text-sub"]}`}
            dangerouslySetInnerHTML={{
              __html: t("fanclub_benefits_content_bronze_text"),
            }}
          />
        </div>
        <div className={styles["fanclub-children"]}>
          <h4 className={styles["fanclub-children-title-sub"]}>
            <img src={imgSilver} alt={t("fanclub_plan_silver")} />
            {t("fanclub_benefits_content_silver_title")}
          </h4>
          <p
            className={`${styles["fanclub-children-text"]} ${styles["fanclub-children-text-sub"]}`}
            dangerouslySetInnerHTML={{
              __html: t("fanclub_benefits_content_silver_text"),
            }}
          />
        </div>
        <div className={styles["fanclub-children"]}>
          <h4 className={styles["fanclub-children-title-sub"]}>
            <img src={imgGold} alt={t("fanclub_plan_gold")} />
            {t("fanclub_benefits_content_gold_title")}
          </h4>
          <p
            className={`${styles["fanclub-children-text"]} ${styles["fanclub-children-text-sub"]}`}
            dangerouslySetInnerHTML={{
              __html: t("fanclub_benefits_content_gold_text"),
            }}
          />
        </div>
        <p
          className={styles["fanclub-benefits-content-text-bottom"]}
          dangerouslySetInnerHTML={{
            __html: t("fanclub_benefits_content_enjoy_text"),
          }}
        />
      </div>
    </>
  );
};

export default PlanList;
