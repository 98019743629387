import React, { useState } from "react";
import ReactPlayer from "react-player";
import _ from "lodash";

// material ui

// components

// style
import styles from "./List.module.scss";

// img

// const
import { SINGING_LIST } from "const/const";

const SingingList = ({ num = null, type = null }) => {
  const typeList = _.filter(SINGING_LIST, (val) => {
    if (type !== null && val.type === type) {
      return val;
    } else if (type === null) {
      return val;
    }
  });

  const [list] = useState(num === null ? typeList : typeList.slice(0, num));

  return (
    <div>
      {list.map((item, index) => {
        return (
          <article key={index} className={styles["list-item"]}>
            <figure className={styles["list-item-img"]}>
              <ReactPlayer
                url={item.url}
                className={styles["list-item-player"]}
              />
            </figure>
            <h3 className={styles["list-item-title"]}>{item.name}</h3>
            <p className={styles["list-item-date"]}>{item.date}</p>
          </article>
        );
      })}
    </div>
  );
};

export default SingingList;
