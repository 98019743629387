import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import {
  TransformWrapper,
  TransformComponent,
} from "@pronestor/react-zoom-pan-pinch";

// icon
import { Close, ZoomOutMap } from "@material-ui/icons";

// material ui
import { Modal } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import Slick from "components/Slick/Slick";
import NotFound from "components/NotFound/NotFound";

// style
import styles from "./Gallery.module.scss";

// img
// takehana
import Takehana1 from "assets/img/img-takehana-thumd-01.jpg";
import Takehana2 from "assets/img/img-takehana-thumd-02.jpg";
import Takehana3 from "assets/img/img-takehana-thumd-03.jpg";
import Takehana4 from "assets/img/img-takehana-thumd-04.jpg";
import TakehanaDetail1 from "assets/img/img-takehana-detail-01.jpg";
import TakehanaDetail2 from "assets/img/img-takehana-detail-02.jpg";
import TakehanaDetail3 from "assets/img/img-takehana-detail-03.jpg";
import TakehanaDetail4 from "assets/img/img-takehana-detail-04.jpg";
import Cno1 from "assets/img/img-cno-thumd-01.jpg";
import CnoDetail1 from "assets/img/img-cno-detail-01.jpg";
import Umi1 from "assets/img/img-umi-thumd-01.jpg";
import Umi2 from "assets/img/img-umi-thumd-02.jpg";
import Umi3 from "assets/img/img-umi-thumd-03.jpg";
import Umi5 from "assets/img/img-umi-thumd-05.jpg";
import Umi6 from "assets/img/img-umi-thumd-06.jpg";
import Umi7 from "assets/img/img-umi-thumd-07.jpg";
import Umi8 from "assets/img/img-umi-thumd-08.jpg";
import Umi9 from "assets/img/img-umi-thumd-09.jpg";
import Umi10 from "assets/img/img-umi-thumd-10.jpg";
import Umi11 from "assets/img/img-umi-thumd-11.jpg";
import Umi12 from "assets/img/img-umi-thumd-12.jpg";
import Umi13 from "assets/img/img-umi-thumd-13.jpg";
import UmiDetail1 from "assets/img/img-umi-detail-01.jpg";
import UmiDetail2 from "assets/img/img-umi-detail-02.jpg";
import UmiDetail3 from "assets/img/img-umi-detail-03.jpg";
import UmiDetail5 from "assets/img/img-umi-detail-05.jpg";
import UmiDetail6 from "assets/img/img-umi-detail-06.jpg";
import UmiDetail7 from "assets/img/img-umi-detail-07.jpg";
import UmiDetail8 from "assets/img/img-umi-detail-08.jpg";
import UmiDetail9 from "assets/img/img-umi-detail-09.jpg";
import UmiDetail10 from "assets/img/img-umi-detail-10.jpg";
import UmiDetail11 from "assets/img/img-umi-detail-11.jpg";
import UmiDetail12 from "assets/img/img-umi-detail-12.jpg";
import UmiDetail13 from "assets/img/img-umi-detail-13.jpg";
import Reimei01 from "assets/img/img-reimei-thumd-01.jpg";
import Reimei02 from "assets/img/img-reimei-thumd-02.jpg";
import ReimeiDetail01 from "assets/img/img-reimei-detail-01.jpg";
import ReimeiDetail02 from "assets/img/img-reimei-detail-02.jpg";
import Hizumi01 from "assets/img/img-hizumi-thumd-01.jpg";
import Hizumi02 from "assets/img/img-hizumi-thumd-02.jpg";
import HizumiDetail01 from "assets/img/img-hizumi-detail-01.jpg";
import HizumiDetail02 from "assets/img/img-hizumi-detail-02.jpg";

// constant

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: "#453059",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    width: "100%",
  },
}));

const GalleryDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { name } = useParams();
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState("");
  const [defalhtPosition, setDefalhtPosition] = useState(null);

  const twitterLink = {
    "takehana-note": "nano_phan",
    cno: "c_nov00",
    umi: "umi_cryblue",
    reimei: "Reimei_illus",
    hizumi: "__h_zm",
  };

  const lists = {
    "takehana-note": [
      {
        thumd: Takehana4,
        img: TakehanaDetail4,
        name: t("gallery_takehana_04"),
      },
      {
        thumd: Takehana3,
        img: TakehanaDetail3,
        name: t("gallery_takehana_03"),
      },
      {
        thumd: Takehana2,
        img: TakehanaDetail2,
        name: t("gallery_takehana_02"),
      },
      {
        thumd: Takehana1,
        img: TakehanaDetail1,
        name: t("gallery_takehana_01"),
      },
    ],
    cno: [
      {
        thumd: Cno1,
        img: CnoDetail1,
        name: t("gallery_cno_01"),
      },
    ],
    umi: [
      {
        thumd: Umi13,
        img: UmiDetail13,
        name: t("gallery_umi_13"),
      },
      {
        thumd: Umi12,
        img: UmiDetail12,
        name: t("gallery_umi_12"),
      },
      {
        thumd: Umi11,
        img: UmiDetail11,
        name: t("gallery_umi_11"),
      },
      {
        thumd: Umi10,
        img: UmiDetail10,
        name: t("gallery_umi_10"),
      },
      {
        thumd: Umi9,
        img: UmiDetail9,
        name: t("gallery_umi_09"),
      },
      {
        thumd: Umi8,
        img: UmiDetail8,
        name: t("gallery_umi_08"),
      },
      {
        thumd: Umi7,
        img: UmiDetail7,
        name: t("gallery_umi_07"),
      },
      {
        thumd: Umi6,
        img: UmiDetail6,
        name: t("gallery_umi_06"),
      },
      {
        thumd: Umi5,
        img: UmiDetail5,
        name: t("gallery_umi_05"),
      },
      {
        thumd: Umi3,
        img: UmiDetail3,
        name: t("gallery_umi_03"),
      },
      {
        thumd: Umi2,
        img: UmiDetail2,
        name: t("gallery_umi_02"),
      },
      {
        thumd: Umi1,
        img: UmiDetail1,
        name: t("gallery_umi_01"),
      },
    ],
    reimei: [
      {
        thumd: Reimei02,
        img: ReimeiDetail02,
        name: t("gallery_reimei_02"),
      },
      {
        thumd: Reimei01,
        img: ReimeiDetail01,
        name: t("gallery_reimei_01"),
      },
    ],
    hizumi: [
      {
        thumd: Hizumi02,
        img: HizumiDetail02,
        name: t("gallery_hizumi_02"),
      },
      {
        thumd: Hizumi01,
        img: HizumiDetail01,
        name: t("gallery_hizumi_01"),
      },
    ],
  };

  useEffect(() => {
    if (current !== "") {
      const image = new Image();
      image.src = current.img;

      image.onload = function () {
        setDefalhtPosition(image.width / 2 - window.innerWidth / 2);
      };
    } else {
      setDefalhtPosition(null);
    }
  }, [current]);

  if (lists[name] === void 0) {
    return <NotFound backType="back" />;
  } else {
    return (
      <InnerLayout
        headType="lower"
        title={t(`name-${name}`)}
        path={`https://twitter.com/${twitterLink[name]}`}
      >
        <Helmet>
          <title>{`${t(`name-${name}`)} - ${t("common_title")}`}</title>
          <meta
            property="og:title"
            content={`${t(`name-${name}`)} - ${t("common_title")}`}
          />
          <meta
            property="og:site_name"
            content={`${t(`name-${name}`)} - ${t("common_title")}`}
          />
        </Helmet>

        <Modal
          className={`${classes.modal} ${styles["gallery-modal"]}`}
          open={open}
          onClose={() => {
            setOpen(false);
            setCurrent("");
            setDefalhtPosition(null);
          }}
        >
          <div className={classes.paper}>
            <p
              className={styles["gallery-modal-close"]}
              onClick={() => {
                setOpen(false);
                setCurrent("");
                setDefalhtPosition(null);
              }}
            >
              <Close />
            </p>
            {current !== "" && defalhtPosition !== null && (
              <TransformWrapper
                minScale="0"
                initialPositionX={-defalhtPosition}
              >
                <TransformComponent
                  wrapperStyle={{
                    maxWidth: "100%",
                    maxHeight: "calc(100vh - 200px)",
                    margin: "0 auto",
                  }}
                >
                  <img src={current.img} alt={t(current.name)} />
                </TransformComponent>
              </TransformWrapper>
            )}
          </div>
        </Modal>
        <div className={`${styles["content-wrapper"]}`}>
          <div
            className={`${styles["content-container"]} ${styles["gallery-detail-text-container"]}`}
          >
            <p className={styles["gallery-detail-text"]}>
              {t("gallery_text_description")}
            </p>
          </div>
          <Slick>
            {lists[name].map((val, index) => {
              return (
                <div className={styles["gallery-detail-item"]} key={index}>
                  <figure
                    onClick={() => {
                      setOpen(true), setCurrent(val);
                    }}
                  >
                    <img src={val.thumd} alt={t(val.name)} />
                    <span className={styles["gallery-detail-item-zoom"]}>
                      <ZoomOutMap />
                    </span>
                  </figure>
                  <p className={styles["gallery-detail-item-text"]}>
                    {t(val.name)}
                  </p>
                </div>
              );
            })}
          </Slick>

          <div
            className={`${styles["content-container"]} ${styles["gallery-detail-text-container"]}`}
          >
            <p
              className={styles["gallery-attention"]}
              dangerouslySetInnerHTML={{ __html: t("gallery_attention_text") }}
            />
          </div>
        </div>
      </InnerLayout>
    );
  }
};

export default GalleryDetail;
