import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import DisplayPage from "components/Cms/DisplayPage";
import NotFound from "components/NotFound/NotFound";

// constant

const Page = () => {
  const { slug } = useParams();
  const [notFound, setNotFound] = useState(false);

  // load
  const [article] = useRest(
    `Content/Cms/@pages:loadSlug`,
    { slug: slug },
    true
  );

  useEffect(() => {
    if (article === false) {
      setNotFound(true);
    } else if (article !== null && article !== false) {
      setNotFound(false);
    }
  }, [article]);

  return (
    <>
      {!notFound && <DisplayPage param={slug} article={article} />}
      {notFound && <NotFound backType="back" />}
    </>
  );
};

export default Page;
