import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Loading from "components/Loading/Loading";
import DisplayEvent from "components/Event/DisplayEvent";
import NotFound from "components/NotFound/NotFound";

// style
import styles from "./Live.module.scss";

// constant
import { IMG_VARIATION_INFOMATION_DETAIL } from "const/const";

const Live = () => {
  const { t } = useTranslation();
  const { slug } = useParams();

  const [article] = useRest(
    `Planner/Schedule/${slug}`,
    { image_variation: IMG_VARIATION_INFOMATION_DETAIL },
    true
  );

  if (article === false) {
    return <NotFound />;
  } else {
    return (
      <InnerLayout
        headType="lower"
        titleVariant="body1"
        title={t("live_title")}
      >
        <div className={`${styles["content-wrapper"]}`}>
          <div className={styles["content-container"]}>
            {article === null && <Loading />}
            {article !== null && <DisplayEvent article={article} />}
          </div>
        </div>
      </InnerLayout>
    );
  }
};

export default Live;
