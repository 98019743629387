import React from "react";
import Slider from "react-slick";

// style
import styles from "./Slick.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// utils
import { SlickOptions } from "const/const";

const Slick = ({ children }) => {
  return (
    <Slider {...SlickOptions} className={styles["slick"]}>
      {children}
    </Slider>
  );
};

export default Slick;
