import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

// material ui
import Button from "@material-ui/core/Button";

// styles
import styles from "./Btn.module.scss";

// imgx
import iconArrowLeft from "assets/img/icon-arrow-left.png";
import iconArrowRight from "assets/img/icon-arrow-right.png";

const Btn = ({
  btnTypes = "button",
  type = "primary",
  children = "default",
  to = "/",
  onClick = null,
  className = null,
  disabled = false,
  iconPosition = "end",
}) => {
  const btnLink = () => {
    if (to.indexOf("http") != -1) {
      return (
        <Button
          className={clsx(styles["btn"], {
            [styles["btn-primary"]]: type === "primary",
            [styles["btn-secondary"]]: type === "secondary",
            [styles["btn-danger"]]: type === "danger",
            [className]: className !== null,
          })}
          component="a"
          endIcon={<img src={iconArrowLeft} />}
          href={to}
          target="_blank"
          rel="noreferrer"
          disabled={disabled}
        >
          {children}
        </Button>
      );
    } else if (btnTypes === "submit") {
      return (
        <Button
          className={clsx(styles["btn"], {
            [styles["btn-primary"]]: type === "primary",
            [styles["btn-secondary"]]: type === "secondary",
            [styles["btn-danger"]]: type === "danger",
            [className]: className !== null,
          })}
          type="submit"
          endIcon={<img src={iconArrowRight} />}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    } else {
      if (iconPosition === "end") {
        return (
          <Button
            className={clsx(styles["btn"], {
              [styles["btn-primary"]]: type === "primary",
              [styles["btn-secondary"]]: type === "secondary",
              [styles["btn-danger"]]: type === "danger",
              [className]: className !== null,
            })}
            component={Link}
            endIcon={<img src={iconArrowRight} />}
            to={to}
            disabled={disabled}
          >
            {children}
          </Button>
        );
      } else if (iconPosition === "start") {
        return (
          <Button
            className={clsx(styles["btn"], {
              [styles["btn-primary"]]: type === "primary",
              [styles["btn-secondary"]]: type === "secondary",
              [styles["btn-danger"]]: type === "danger",
              [className]: className !== null,
            })}
            component={Link}
            startIcon={<img src={iconArrowRight} />}
            to={to}
            disabled={disabled}
          >
            {children}
          </Button>
        );
      } else {
        return (
          <Button
            className={clsx(styles["btn"], {
              [styles["btn-primary"]]: type === "primary",
              [styles["btn-secondary"]]: type === "secondary",
              [styles["btn-danger"]]: type === "danger",
              [className]: className !== null,
            })}
            component={Link}
            to={to}
            disabled={disabled}
          >
            {children}
          </Button>
        );
      }
    }
  };

  const btn = () => {
    if (iconPosition === "end") {
      return (
        <Button
          className={clsx(styles["btn"], {
            [styles["btn-primary"]]: type === "primary",
            [styles["btn-secondary"]]: type === "secondary",
            [styles["btn-danger"]]: type === "danger",
            [className]: className !== null,
          })}
          endIcon={<img src={iconArrowRight} />}
          onClick={() => onClick()}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    } else if (iconPosition === "start") {
      return (
        <Button
          className={clsx(`${styles["btn"]}`, {
            [styles["btn-primary"]]: type === "primary",
            [styles["btn-secondary"]]: type === "secondary",
            [styles["btn-danger"]]: type === "danger",
            [className]: className !== null,
          })}
          startIcon={<img src={iconArrowLeft} />}
          onClick={() => onClick()}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    } else {
      return (
        <Button
          className={clsx(`${styles["btn"]}`, {
            [styles["btn-primary"]]: type === "primary",
            [styles["btn-secondary"]]: type === "secondary",
            [styles["btn-danger"]]: type === "danger",
            [className]: className !== null,
          })}
          onClick={() => onClick()}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    }
  };

  const buildBtn = () => {
    switch (onClick) {
      case null:
        return btnLink();
      default:
        return btn();
    }
  };

  return <>{buildBtn()}</>;
};

export default Btn;
