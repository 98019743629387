import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import BlogList from "components/List/BlogList";
import Pagination from "components/Pagination/Pagination";

// style
import styles from "./FanclubBlog.module.scss";

// constant
import { FANCLUB_BLOG_PARAMS } from "const/const";

const FanclubBlog = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pageNum } = useParams();

  //pages
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );

  const [news, setNews] = useRest("Membership/@fanclub:cms_search", {
    ...FANCLUB_BLOG_PARAMS,
    ...{ page_no: currentPage },
  });

  const newsRefresh = useRestRefresh("Membership/@fanclub:cms_search", {
    ...FANCLUB_BLOG_PARAMS,
    ...{ page_no: currentPage },
  });

  useEffect(() => {
    setNews(null);
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
  }, [location.pathname, pageNum]);

  useEffect(() => {
    if (news === null) {
      newsRefresh();
    }
  }, [currentPage, news, newsRefresh]);

  return (
    <InnerLayout headType="fanclub" title={t("common_blog")}>
      <Helmet>
        <title>{`${t("common_blog")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("common_blog")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("common_blog")} - ${t("common_title")}`}
        />
      </Helmet>
      <div className={`${styles["content-wrapper"]}`}>
        <div className={styles["content-container"]}>
          {news === null && <Loading />}
          {news !== null && (
            <BlogList vals={news?.data.data} path="fanclub/blog" />
          )}
          {news !== null && (
            <Pagination
              setCurrentPage={setCurrentPage}
              defaultPage={currentPage}
              path="/blog"
              count={news?.paging.page_max}
            />
          )}
        </div>
      </div>
    </InnerLayout>
  );
};

export default FanclubBlog;
