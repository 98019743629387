import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";

//material ui

// component
import Loading from "components/Loading/Loading";
import Pagination from "components/Pagination/Pagination";
import ShopItem from "./shopItem";

// style
import styles from "./shopItem.module.scss";

// constant

const ShopItems = ({ paging = true, btns = true, view = 10 }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const cartRefresh = useRestRefresh("Catalog/Cart/@");

  const { pageNum } = useParams();
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );

  const [products] = useRest(
    "Catalog/Product:search",
    {
      page_no: currentPage,
      results_per_page: view,
      image_variation:
        "resolution=200&background_color=white&flatten&strip&format=jpeg&page=*&scale=880x800",
      sort: "Basic.Priority:desc",
    },
    true
  );

  useEffect(() => {
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
  }, [location.pathname, pageNum]);

  return (
    <>
      {products === null && <Loading />}
      {products !== null && (
        <>
          {products.data.data.length === 0 && (
            <p className={styles["shop-notfound"]}>{t("common_notfound")}</p>
          )}
          {products.data.data.length !== 0 && (
            <>
              <div>
                {products.data.data.map((val, index) => {
                  return (
                    <ShopItem
                      btns={btns}
                      val={val}
                      key={index}
                      cartRefresh={cartRefresh}
                    />
                  );
                })}
              </div>
              {paging && (
                <Pagination
                  setCurrentPage={setCurrentPage}
                  defaultPage={currentPage}
                  path=""
                  count={products?.paging.page_max}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ShopItems;
