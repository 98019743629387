import React, { useState, useEffect } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

// material ui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

// component
import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loading/Loading";
import Button from "components/Btns/Btn";

// styles
import styles from "../MyPage.module.scss";

// const
import { HISTORY_PAGE_COUNT } from "const/const";

const History = () => {
  const { t } = useTranslation();
  const { pageNum } = useParams();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );
  const [order] = useRest(
    "Order",
    {
      page_no: currentPage,
      results_per_page: HISTORY_PAGE_COUNT,
      Status: { $in: ["completed", "pending-initiated"] },
    },
    true
  );

  useEffect(() => {
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
  }, [location.pathname, pageNum]);

  return (
    <div>
      {order === null && <Loading />}
      {order !== null && order?.data.length === 0 && (
        <p className={styles["mypage__notfound"]}>
          {t("mypage_history_no_order_text")}
        </p>
      )}
      {order !== null && order?.data.length !== 0 && (
        <TableContainer>
          <Table className={styles["history-table"]}>
            <TableHead>
              <TableRow>
                <TableCell>{t("mypage_history_label_no")}</TableCell>
                <TableCell>{t("mypage_history_label_date")}</TableCell>
                <TableCell>{t("mypage_history_label_status")}</TableCell>
                <TableCell>{t("mypage_history_label_price")}</TableCell>
                <TableCell>{t("mypage_history_label_pdf")}</TableCell>
                <TableCell align="center">
                  {t("mypage_history_label_detail")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order?.data.map((item, index) => {
                const invoiceDate = moment(
                  parseInt(item.Created?.unixms)
                ).format("YYYY-MM-DD HH:mm:ss");
                const invoiceNum = item.Invoice_Number;

                return (
                  <TableRow key={index}>
                    <TableCell>
                      {invoiceNum !== null ? invoiceNum : "-"}{" "}
                    </TableCell>
                    <TableCell>
                      {invoiceDate !== "Invalid date" ? invoiceDate : "-"}
                    </TableCell>
                    <TableCell>{t(`order_status_${item.Status}`)}</TableCell>
                    <TableCell>{item.Total_Vat.display}</TableCell>
                    <TableCell>
                      {item.Invoice_Url === void 0 ? (
                        "-"
                      ) : (
                        <a href={item.Invoice_Url}>PDF</a>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {item.Status === "completed" ||
                      item.Status === "pending-initiated" ? (
                        <Button
                          to={`/order/${item.Order__}?type=history`}
                          iconPosition="noting"
                        >
                          {t("mypage_history_label_detail")}
                        </Button>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {order !== null && order?.data.length !== 0 && (
        <Pagination
          setCurrentPage={setCurrentPage}
          defaultPage={currentPage}
          count={order?.paging.page_max}
        />
      )}
    </div>
  );
};

export default History;
