import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getToken } from "@karpeleslab/klbfw";
import { PxlPlayer } from "@pxljp/pxl-player-react";
import { rest } from "@karpeleslab/klbfw";

// component
import Loading from "components/Loading/Loading";

// img
import DefaultImg from "assets/img/img-streaming-default.png";

// style
import styles from "./Player.module.scss";

const Player = ({
  article = null,
  eventId = null,
  setStreamHeight = null,
  backText = {
    text: "common_streaming_back_text",
    path: null,
    buyTicketText: "common_buy_ticket",
  },
}) => {
  const { t } = useTranslation();
  const [streamInfo, setStreamInfo] = useState(null);
  const [chatLog, setChatLog] = useState([]);
  const streamElement = useRef(null);

  const [options] = useState({
    eventId,
    clientId: "none",
    code: "none",
    domain: location.origin,
    endpoint: "none",
    getHeaderAuthorization: () => `Session ${getToken()}`,
    i18n: "ja-JP",
  });

  useEffect(() => {
    let unmounted = false;

    let query = {
      index: 0,
    };

    if (chatLog.length !== 0) {
      query.index = chatLog.slice(-1)[0].Index;
    }

    rest(`Planner/Event/${eventId}/Chat:poll`, "POST", query)
      .then((res) => {
        if (unmounted) return;
        setChatLog([...chatLog, ...res.data]);
        setTimeout(() => {
          setStreamHeight(
            streamElement.current?.getBoundingClientRect().height
          );
        }, 2000);
      })
      .catch(() => {});

    return () => {
      unmounted = true;
    };
  }, [chatLog, article]);

  // streaming auto start
  const updatePlanner = (state) => {
    rest(`Planner/Event/${eventId}:stream`)
      .then((res) => {
        if (!state.enabled) return;
        setStreamInfo(res.data);

        if (res.data.Status === "none") {
          let t = res.data.Hls_Recheck_Delay;
          if (t < 5) t = 5;
          state.timeout = setTimeout(() => updatePlanner(state), t * 100);
        }
      })
      .catch((res) => {
        if (res.data.Status === "none") {
          if (!state.enabled) return;
          state.timeout = setTimeout(() => updatePlanner(state), 60000);
        }
      });
  };

  useEffect(() => {
    let state = { enabled: true };
    updatePlanner(state);
    return () => {
      state.enabled = false;
      setStreamInfo(null);
      clearTimeout(state.timeout);
    };
  }, [article]);

  return (
    <div ref={streamElement} className={styles["streaming"]}>
      {streamInfo === null && <Loading className={styles["streaming-load"]} />}
      {streamInfo !== null && (
        <>
          {!streamInfo.Has_Seat && (
            <div className={styles["streaming-ticket-buy"]}>
              <div className={styles["streaming-ticket-buy-text"]}>
                <p dangerouslySetInnerHTML={{ __html: t(backText.text) }} />
                {backText.path !== null ||
                  (backText.path !== void 0 && (
                    <Link to={backText.path}>{t(backText.buyTicketText)}</Link>
                  ))}
              </div>
              <img className={styles["streaming-img"]} src={DefaultImg} />
            </div>
          )}
          {streamInfo.Has_Seat && (
            <>
              {streamInfo.Hls_Url !== "" && <PxlPlayer options={options} />}
              {streamInfo.Hls_Url === "" && (
                <img className={styles["streaming-img"]} src={DefaultImg} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Player;
