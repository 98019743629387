import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

//material ui

// components
import ContentTitle from "components/Typography/ContentTitle";
import EventList from "components/List/EventList";
import Loading from "components/Loading/Loading";
import TextLink from "components/Link/TextLink";

// style
import styles from "./Live.module.scss";

// img

// constant
import { LIVE_PARAMS_TOP } from "const/const";

const Live = () => {
  const { t } = useTranslation();
  const [event] = useRest(
    "Planner/@planner/Schedule",
    {
      ...LIVE_PARAMS_TOP,
      ...{ Tag: "project-live" },
    },
    true
  );

  return (
    <div className={`${styles["content-wrapper"]}`}>
      <div className={styles["content-container"]}>
        <ContentTitle>{t("common_live")}</ContentTitle>
        {event === null && <Loading />}
        {event !== null && (
          <EventList type="live" path="live" vals={event?.data} />
        )}
        <TextLink path="/live" />
      </div>
    </div>
  );
};

export default Live;
