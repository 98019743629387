import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// component
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import TextLink from "components/Link/TextLink";

// utils
import { buildDate } from "utils/BuildDate";

// styles
import styles from "./Cms.module.scss";

const DisplayArticle = ({ article }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const articleDetail = article?.data?.content_cms_entry_data;

  return (
    <>
      {article === null && (
        <div className={styles["cms-load"]}>
          <Loading />
        </div>
      )}
      {article !== null && article !== false && (
        <>
          <Helmet>
            <title>{`${article.data.content_cms_entry_data.Title} - ${t(
              "common_title"
            )}`}</title>
            <meta
              property="og:title"
              content={`${article.data.content_cms_entry_data.Title} - ${t(
                "common_title"
              )}`}
            />
            <meta
              property="og:site_name"
              content={`${article.data.content_cms_entry_data.Title} - ${t(
                "common_title"
              )}`}
            />
          </Helmet>
          <div className={styles["cms-head"]}>
            <h1 className={styles["cms-title"]}>
              {article.data.content_cms_entry_data.Title}
            </h1>
            <p className={styles["cms-date"]}>
              {buildDate(
                parseInt(article.data.content_cms_entry_data.Published.unixms),
                "YYYY.MM.DD"
              )}
            </p>
          </div>
          <div
            className={styles["cms-contents"]}
            dangerouslySetInnerHTML={{
              __html: articleDetail.Contents,
            }}
          />
          <TextLink
            onClick={() => {
              history.goBack();
            }}
            text_token="common_btn_back_list"
            type="back"
          />
        </>
      )}
    </>
  );
};

export default DisplayArticle;
