import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// components
import Sns from "components/Sns/Sns";
import Twitter from "components/Sns/Twitter";

// style
import styles from "./Footer.module.scss";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Twitter />
      <footer className={styles["footer"]}>
        <ul className={styles["footer-list"]}>
          <li className={styles["footer-list-item"]}>
            <Link to="/page/terms_of_service">{t("common_terms_of_use")}</Link>
          </li>
          <li className={styles["footer-list-item"]}>
            <Link to="/page/privacy_policy">{t("common_privacy")}</Link>
          </li>
          <li className={styles["footer-list-item"]}>
            <Link to="/page/scta">{t("common_scta")}</Link>
          </li>
        </ul>
        <div className={styles["footer-sns"]}>
          <Sns />
        </div>
        <small className={styles["footer-copy"]}>
          {t("common_copy", { year: new Date().getFullYear() })}
        </small>
      </footer>
    </>
  );
};

export default Footer;
