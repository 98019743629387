import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

//material ui

// components
import ContentTitle from "components/Typography/ContentTitle";
import TextList from "components/List/TextList";
import Loading from "components/Loading/Loading";
import TextLink from "components/Link/TextLink";

// img

// style
import styles from "./News.module.scss";

// constant
import { NEWS_PARAMS_TOP } from "const/const";

const News = () => {
  const { t } = useTranslation();
  const [news] = useRest("Content/Cms/@news:search", NEWS_PARAMS_TOP, true);

  return (
    <div className={`${styles["content-wrapper"]}`}>
      <div className={styles["content-container"]}>
        <ContentTitle>{t("common_news")}</ContentTitle>
        {news === null && <Loading />}
        {news !== null && <TextList vals={news?.data.data} />}
        <TextLink path="/news" />
      </div>
    </div>
  );
};

export default News;
