import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import InnerLayout from "components/Layout/InnerLayout";
import DisplayFanclub from "components/Cms/DisplayFanclub";
import NotFound from "components/NotFound/NotFound";
import Loading from "components/Loading/Loading";
import BlogList from "components/List/BlogList";

// style
import styles from "./FanclubBlog.module.scss";

// constant
import {
  IMG_VARIATION_BLOG_DETAIL,
  FANCLUB_DETAIL_BLOG_PARAMS,
} from "const/const";

const FanclubBlogDetail = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [notFound, setNotFound] = useState(false);

  const [news] = useRest("Membership/@fanclub:cms_search", {
    ...FANCLUB_DETAIL_BLOG_PARAMS,
  });

  const [article] = useRest(
    `Membership/@fanclub:loadArticleSlug`,
    { slug: slug, image_variation: IMG_VARIATION_BLOG_DETAIL },
    true
  );

  useEffect(() => {
    if (article === false) {
      setNotFound(true);
    } else if (article !== null) {
      setNotFound(false);
    }
  }, [article]);

  return (
    <>
      {notFound && <NotFound backType="back" />}
      {!notFound && (
        <InnerLayout
          headType="fanclub"
          titleVariant="body1"
          title={t("common_blog")}
          content="detail"
        >
          <div className={`${styles["content-wrapper"]}`}>
            <div className={styles["content-container"]}>
              <DisplayFanclub article={article} />
              {news === null && <Loading />}
              {news !== null && (
                <BlogList vals={news?.data.data} path="fanclub/blog" />
              )}
            </div>
          </div>
        </InnerLayout>
      )}
    </>
  );
};

export default FanclubBlogDetail;
