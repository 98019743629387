import React from "react";
import { useTranslation } from "react-i18next";

// material ui
import { Typography } from "@material-ui/core";

// icon
import { Twitter } from "@material-ui/icons";

// style
import styles from "../Header.module.scss";

// img
import Cara from "assets/img/bg-mew.png";

const HeaderLower = ({ title, titleVariant = "h1", path = null }) => {
  const { t } = useTranslation();

  return (
    <header className={styles["header-lower"]}>
      <div className={styles["header-lower-content"]}>
        <div className={styles["header-lower-cara"]}>
          <img src={Cara} alt={t("common_title")} />
        </div>
        <div className={styles["header-lower-inner"]}>
          <Typography
            align="center"
            className={styles["header-lower-title"]}
            variant={titleVariant}
          >
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Typography>
          {path !== null && (
            <a
              href={path}
              rel="noreferrer"
              target="_blank"
              className={styles["header-lower-sns"]}
            >
              <Twitter />
            </a>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderLower;
