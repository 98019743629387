import React from "react";

// material ui
import { Typography } from "@material-ui/core";

// style
import styles from "./Typography.module.scss";
import clsx from "clsx";

const Paragraph = ({ children, className = null }) => {
  return (
    <Typography
      className={clsx(styles["paragraph"], {
        [className]: className !== null,
      })}
      variant="body1"
    >
      <span dangerouslySetInnerHTML={{ __html: children }}></span>
    </Typography>
  );
};

export default Paragraph;
