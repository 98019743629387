import React from "react";
import { useTranslation } from "react-i18next";

//material ui

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import ShopItems from "components/shopItem/shopItems";

// style
import styles from "./Shop.module.scss";

// constant

const News = () => {
  const { t } = useTranslation();

  return (
    <InnerLayout headType="lower" title={t("common_shop")}>
      <Helmet>
        <title>{`${t("common_shop")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("common_shop")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("common_shop")} - ${t("common_title")}`}
        />
      </Helmet>
      <div className={`${styles["content-wrapper"]}`}>
        <div className={styles["content-container"]}>
          <ShopItems />
        </div>
      </div>
    </InnerLayout>
  );
};

export default News;
