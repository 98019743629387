import React from "react";

// icon
import { Twitter, YouTube } from "@material-ui/icons";

// style
import styles from "./Sns.module.scss";

// const
import { SNS_LIST } from "const/const";

const SnsList = () => {
  const buildIcon = (brand) => {
    switch (brand) {
      case "twitter":
        return <Twitter />;
      case "youtube":
        return <YouTube />;
      default:
        return null;
    }
  };

  return (
    <>
      {SNS_LIST.map((item, index) => {
        return (
          <a
            key={index}
            href={item.url}
            rel="noreferrer"
            target="_blank"
            className={styles["sns-btn"]}
          >
            {buildIcon(item.brand)}
          </a>
        );
      })}
    </>
  );
};

export default SnsList;
