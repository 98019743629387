import React from "react";
import clsx from "clsx";
import Toast from "components/Toast/Toast";

// conponents
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

// style
import styles from "./Layout.module.scss";

// img
import Logo from "assets/img/bg-lower-logo.png";

const InnerLayout = ({
  children,
  headType = "top",
  title = "common",
  titleVariant = "h1",
  content = null,
  path = null,
}) => {
  return (
    <div
      className={clsx(styles["layout"], {
        [styles["layout-lower"]]: headType === "lower",
        [styles["layout-white"]]: headType === "white",
        [styles["layout-fanclub"]]: headType === "fanclub",
        [styles["layout-live"]]: headType === "live",
      })}
      style={{
        backgroundImage: headType === "lower" ? `url(${Logo})` : "",
      }}
    >
      <Header
        headType={headType}
        titleVariant={titleVariant}
        title={title}
        content={content}
        path={path}
      />
      <main className={styles["container"]}>{children}</main>
      <Footer />
      <Toast duration={5000} />
    </div>
  );
};

export default InnerLayout;
