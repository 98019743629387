import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

//material ui

// components
import ContentTitle from "components/Typography/ContentTitle";
import Slick from "components/Slick/Slick";

// style
import styles from "./Movie.module.scss";

// const
import { SINGING_LIST } from "const/const";

const Movie = () => {
  const { t } = useTranslation();

  return (
    <div className={`${styles["content-wrapper"]}`}>
      <div className={styles["content-container"]}>
        <ContentTitle>{t("common_movie")}</ContentTitle>
      </div>
      <Slick>
        {SINGING_LIST.map((val, index) => {
          if (index < 4) {
            return (
              <div key={index} className={styles["movie-item"]}>
                <ReactPlayer url={val.url} width="246px" height="138px" />
              </div>
            );
          } else {
            return false;
          }
        })}
      </Slick>
    </div>
  );
};

export default Movie;
