import React from "react";
import { Switch, Route } from "react-router-dom";

// Material Ui
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { Theme } from "assets/jss/Theme";
import { StylesProvider } from "@material-ui/core/styles";

// layout
import Layout from "components/Layout/Layout";

// page
import Index from "views/Index/Index";
import News from "views/News/News";
import NewsDetail from "views/News/Detail";
import Discography from "views/Discography/Discography";
import Gallery from "views/Gallery/Gallery";
import GalleryDetail from "views/Gallery/Detail";
import Shop from "views/Shop/Shop";
import Live from "views/Live/Live";
import LiveDetail from "views/Live/Detail";
import LiveStreaming from "views/Live/Streaming";
import Contact from "views/Contact/Contact";
import Guideline from "views/Guideline/Guideline";
import Login from "views/Login/Login";
import MyPage from "views/MyPage/MyPage";
import Fanclub from "views/Fanclub/Fanclub";
import NotFound from "views/404/404";
import FanclubPlan from "views/Fanclub/Plan";
import Cart from "views/Cart/Cart";
import Checkout from "views/Checkout/Checkout";
import Page from "views/Page/Page";
import FanclubNews from "views/FanclubNews/FanclubNews";
import FanclubNewsDetail from "views/FanclubNews/Detail";
import FanclubLive from "views/FanclubLive/FanclubLive";
import FanclubLiveDetail from "views/FanclubLive/Detail";
import FanclubBlog from "views/FanclubBlog/FanclubBlog";
import FanclubBlogDetail from "views/FanclubBlog/Detail";
import FanclubMewBox from "views/FanclubMewBox/FanclubMewBox";
import FanclubMewBoxDetail from "views/FanclubMewBox/Detail";
import FanclubGallery from "views/FanclubGallery/FanclubGallery";
import FanclubGalleryDetail from "views/FanclubGallery/Detail";
import Chat from "views/Chat/Chat";
import ForgottenPassword from "views/ForgottenPassword/ForgottenPassword";
import PasswordReset from "views/PasswordReset/PasswordReset";

// settlement
import Reserve from "views/Reserve/Reserve";
import Order from "views/Order/Order";
import Subscribe from "views/Subscribe/Subscribe";

// util
import LoginRequired from "utils/LoginRequired";
import MemberRequired from "utils/MemberRequired";

// mail

const Mail = () => {
  return <Switch></Switch>;
};

const Pages = () => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <StylesProvider injectFirst>
        <Switch>
          <Route exact path="/">
            <Layout>
              <Index />
            </Layout>
          </Route>
          <Route exact path="/discography">
            <Layout>
              <Discography />
            </Layout>
          </Route>
          <Route exact path="/gallery">
            <Layout>
              <Gallery />
            </Layout>
          </Route>
          <Route exact path="/gallery/:name">
            <Layout>
              <GalleryDetail />
            </Layout>
          </Route>
          <Route exact path="/news">
            <Layout>
              <News />
            </Layout>
          </Route>
          <Route exact path="/news/page-:pageNum">
            <Layout>
              <News />
            </Layout>
          </Route>
          <Route exact path="/news/:slug">
            <Layout>
              <NewsDetail />
            </Layout>
          </Route>
          <Route exact path="/live">
            <Layout>
              <Live />
            </Layout>
          </Route>
          <Route exact path="/live/page-:pageNum">
            <Layout>
              <Live />
            </Layout>
          </Route>
          <Route exact path="/live/:slug">
            <Layout>
              <LiveDetail />
            </Layout>
          </Route>
          <Route exact path="/live/:slug/streaming">
            <LoginRequired>
              <LiveStreaming />
            </LoginRequired>
          </Route>
          <Route exact path="/shop">
            <Layout>
              <Shop />
            </Layout>
          </Route>
          <Route exact path="/shop/page-:pageNum">
            <Layout>
              <Shop />
            </Layout>
          </Route>
          <Route exact path="/guideline">
            <Layout>
              <Guideline />
            </Layout>
          </Route>
          <Route exact path="/contact">
            <Layout>
              <Contact />
            </Layout>
          </Route>
          <Route exact path="/login">
            <Layout>
              <Login />
            </Layout>
          </Route>
          <Route exact path="/cart">
            <Layout>
              <Cart />
            </Layout>
          </Route>
          <Route exact path="/checkout">
            <LoginRequired>
              <Layout>
                <Checkout />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/mypage">
            <LoginRequired>
              <Layout>
                <MyPage type="history" />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/mypage/page-:pageNum">
            <LoginRequired>
              <Layout>
                <MyPage type="history" />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/mypage/user">
            <LoginRequired>
              <Layout>
                <MyPage type="user" />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/mypage/profile">
            <LoginRequired>
              <Layout>
                <MyPage type="profile" />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/mypage/password">
            <LoginRequired>
              <Layout>
                <MyPage type="password" />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/mypage/billing">
            <LoginRequired>
              <Layout>
                <MyPage type="billing" />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/mypage/membership">
            <LoginRequired>
              <Layout>
                <MyPage type="membership" />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub">
            <Layout>
              <Fanclub />
            </Layout>
          </Route>
          <Route exact path="/fanclub/news">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubNews />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/news/page-:pageNum">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubNews />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/news/:slug">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubNewsDetail />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/live">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubLive />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/live/page-:pageNum">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubLive />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/live/:slug">
            <LoginRequired>
              <MemberRequired>
                <FanclubLiveDetail />
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/gallery">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubGallery />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/gallery/page-:pageNum">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubGallery />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/gallery/:slug">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubGalleryDetail />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/blog">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubBlog />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/blog/page-:pageNum">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubBlog />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/blog/:slug">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubBlogDetail />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/mewbox">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubMewBox />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/mewbox/page-:pageNum">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubMewBox />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/fanclub/mewbox/:slug">
            <LoginRequired>
              <MemberRequired>
                <Layout>
                  <FanclubMewBoxDetail />
                </Layout>
              </MemberRequired>
            </LoginRequired>
          </Route>
          <Route exact path="/page/:slug">
            <Page />
          </Route>
          <Route exact path="/reserve/:slug">
            <LoginRequired>
              <Layout>
                <Reserve />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/order/:slug">
            <LoginRequired>
              <Layout>
                <Order />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/subscribe">
            <LoginRequired>
              <Layout>
                <FanclubPlan />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/subscribe/:slug">
            <LoginRequired>
              <Layout>
                <Subscribe />
              </Layout>
            </LoginRequired>
          </Route>
          <Route exact path="/chat/:slug">
            <Chat />
          </Route>
          <Route exact path="/forget-password">
            <ForgottenPassword />
          </Route>
          <Route exact path="/recover-password/:login">
            <PasswordReset />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </StylesProvider>
    </ThemeProvider>
  );
};

const Core = () => {
  return (
    <Switch>
      <Route path="/mail">
        <Mail />
      </Route>
      <Route>
        <Pages />
      </Route>
    </Switch>
  );
};

const Routing = () => {
  return <Core />;
};

export default Routing;
