import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Toast from "components/Toast/Toast";

// conponents
import Helmet from "components/Helmet/Helmet";

// style
import styles from "./Layout.module.scss";

// img
import imgPc from "assets/img/img-pc-display.png";

const Layout = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const pclayout = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    pclayout.current.scrollTo(0, 0);
  }, [location.pathname]);

  const buildLayout = () => {
    return (
      <>
        <div className={styles["layout-wrapper"]}>
          <div className={styles["layout-wrapper-container"]}>
            <div className={styles["layout-wrapper-item"]}>
              <div className={styles["layout-views-container"]}>
                <div ref={pclayout} className={styles["layout-views"]}>
                  {children}
                </div>
              </div>
            </div>
            <div className={styles["layout-wrapper-pc"]}>
              <p>
                <img src={imgPc} alt={t("common_layout_pc")} />
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>{t("common_title")}</title>
        <meta name="description" content={t("common_desc")} />
        <meta property="og:title" content={t("common_title")} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={t("common_desc")} />
        <meta
          property="og:url"
          content={`https://www.mew.jp${location.pathname}`}
        />
        <meta property="og:site_name" content={t("common_title")} />
        <meta property="og:image" content="https://www.mew.jp/img/og-img.jpg" />
      </Helmet>
      {buildLayout()}
      <Toast duration={5000} />
    </div>
  );
};

export default Layout;
