import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import TextLink from "components/Link/TextLink";
import Loading from "components/Loading/Loading";
import EventList from "components/List/EventList";

// utils

// style
import styles from "../Fanclub.module.scss";

// constant
import { LIVE_PARAMS_TOP } from "const/const";

const Live = () => {
  const { t } = useTranslation();

  const [event] = useRest(
    "Planner/@planner/Schedule",
    {
      ...LIVE_PARAMS_TOP,
      ...{ page_no: 0 },
      ...{ Tag: "fanclub:live" },
    },
    true
  );

  return (
    <>
      <h2 className={styles["fanclub-title"]}>{t("common_video")}</h2>
      {event === null && <Loading />}
      {event !== null && <EventList vals={event?.data} path="fanclub/live" />}
      <TextLink
        type="dark"
        path="/fanclub/live"
        text_token={t("common_enter")}
      />
    </>
  );
};

export default Live;
