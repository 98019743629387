import React, { useState } from "react";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import moment from "moment";

// component
import InputForm from "components/Forms/Input";
import SelectInput from "components/Forms/SelectInput";
import Button from "components/Btns/Btn";
import Loading from "components/Loading/Loading";
import { error, success } from "components/Toast/Toast";

// styles
import styles from "../MyPage.module.scss";

const Profile = () => {
  const { t } = useTranslation();
  const [user] = useRest("User:get");
  const userRefresh = useRestRefresh("User:get");
  const [userName, setUserName] = useState(user?.data.Profile.Display_Name);
  const [birth, serBirth] = useState(
    user?.data.Profile.Birthdate === "0000-00-00"
      ? moment().format("YYYY-MM-DD")
      : moment(user?.data.Profile.Birthdate).format("YYYY-MM-DD")
  );
  const [gender, setGender] = useState(user?.data.Profile.Gender);
  const [loading, setLoading] = useState(false);

  const genderArray = [
    { label: t("mypage_label_user_gender_select"), val: "" },
    { label: t("mypage_label_user_gender_select_male"), val: "M" },
    { label: t("mypage_label_user_gender_select_female"), val: "F" },
  ];

  const submitForm = () => {
    setLoading(true);

    const data = {
      Gender: gender === "" ? null : gender,
      Display_Name: userName,
      Birthdate:
        birth === ""
          ? moment().format("YYYY-MM-DD")
          : moment(birth).format("YYYY-MM-DD"),
    };

    rest("User/@/Profile", "PATCH", data)
      .then(() => {
        setLoading(false);
        success("toast_success_text", true, {
          position: "top-center",
          autoClose: 1500,
          onClose: () => {
            userRefresh();
          },
        });
      })
      .catch((err) => {
        setLoading(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  if (user === null) {
    return <Loading />;
  }

  return (
    <div>
      <InputForm
        type="text"
        value={userName}
        name="user-userName"
        label={t("mypage_label_user_name")}
        placeholder={t("input_field_placeholder", {
          text: t("mypage_label_user_name"),
        })}
        handleChange={(event) => {
          setUserName(event.target.value);
        }}
        disabled={loading}
      />
      <InputForm
        type="date"
        value={birth}
        name="user-birth"
        label={t("mypage_label_user_birth")}
        placeholder={t("input_field_placeholder", {
          text: t("mypage_label_user_birth"),
        })}
        handleChange={(event) => {
          serBirth(event.target.value);
        }}
        disabled={loading}
      />
      <SelectInput
        name="user-gender"
        label={t("mypage_label_user_gender")}
        value={gender}
        array={genderArray}
        handleChange={(event) => {
          setGender(event.target.value);
        }}
        disabled={loading}
      />
      <div className={styles["mypage-btns"]}>
        <Button
          onClick={() => {
            submitForm(true);
          }}
          iconPosition="noting"
          disabled={loading}
        >
          {t("common_save")}
        </Button>
      </div>
    </div>
  );
};

export default Profile;
