import React from "react";

// material ui
import { Typography } from "@material-ui/core";

// style
import styles from "./Typography.module.scss";

const SnsTitle = ({ variant = "h2", align = "left", children }) => {
  return (
    <Typography className={styles["sns-title"]} variant={variant} align={align}>
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </Typography>
  );
};

export default SnsTitle;
