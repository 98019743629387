import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// material ui

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";

// style
import styles from "./Gallery.module.scss";

// img
import Takehana from "assets/img/img-gallery-thumdnail-takehana.jpg";
import Cno from "assets/img/img-gallery-thumdnail-cno.jpg";
import Umi from "assets/img/img-gallery-thumdnail-umi.jpg";
import Reimei from "assets/img/img-gallery-thumdnail-reimei.jpg";
import Hizumi from "assets/img/img-gallery-thumdnail-hizumi.jpg";

// constant

const Gallery = () => {
  const { t } = useTranslation();

  return (
    <InnerLayout headType="lower" title={t("common_gallery")}>
      <Helmet>
        <title>{`${t("common_gallery")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("common_gallery")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("common_gallery")} - ${t("common_title")}`}
        />
      </Helmet>
      <div className={`${styles["content-wrapper"]}`}>
        <div className={styles["content-container"]}>
          <ul className={styles["gallery-container"]}>
            <li className={styles["gallery-item"]}>
              <Link to="/gallery/umi">
                <figure className={styles["gallery-item-img"]}>
                  <img src={Umi} alt={"umi"} />
                </figure>
                <p className={styles["gallery-item-text"]}>umi</p>
              </Link>
            </li>
            <li className={styles["gallery-item"]}>
              <Link to="/gallery/hizumi">
                <figure className={styles["gallery-item-img"]}>
                  <img src={Hizumi} alt={"ひずみ"} />
                </figure>
                <p className={styles["gallery-item-text"]}>ひずみ</p>
              </Link>
            </li>
            <li className={styles["gallery-item"]}>
              <Link to="/gallery/reimei">
                <figure className={styles["gallery-item-img"]}>
                  <img src={Reimei} alt={"れいめい"} />
                </figure>
                <p className={styles["gallery-item-text"]}>れいめい</p>
              </Link>
            </li>
            <li className={styles["gallery-item"]}>
              <Link to="/gallery/takehana-note">
                <figure className={styles["gallery-item-img"]}>
                  <img src={Takehana} alt={"竹花ノート"} />
                </figure>
                <p className={styles["gallery-item-text"]}>竹花ノート</p>
              </Link>
            </li>
            <li className={styles["gallery-item"]}>
              <Link to="/gallery/cno">
                <figure className={styles["gallery-item-img"]}>
                  <img src={Cno} alt={"CNO"} />
                </figure>
                <p className={styles["gallery-item-text"]}>CNO</p>
              </Link>
            </li>
          </ul>
          <p
            className={styles["gallery-attention"]}
            dangerouslySetInnerHTML={{ __html: t("gallery_attention_text") }}
          />
        </div>
      </div>
    </InnerLayout>
  );
};

export default Gallery;
