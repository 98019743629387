import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import qs from "qs";
import { error } from "components/Toast/Toast";

// material ui
import { Typography } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import Button from "components/Btns/Btn";
import ReserveList from "components/List/ReserveList";
import LoadingOverLay from "components/Loading/LoadingOverLay";
import BillingInputs from "components/Billing/BillingInputs";

// style
import styles from "./Reserve.module.scss";

const Reserve = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [user] = useRest("User:get");
  const [event] = useRest(`Planner/Schedule/${slug}`);
  const history = useHistory();
  const location = useLocation();
  const hash = qs.parse(location.search.split("?")[1]);
  const [currentSeat, setCurrentSeat] = useState("");

  // load
  const [load, setLoad] = useState(false);

  // form
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [zip, setZip] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [geoVal] = useRest("ThirdParty/Geoip:lookup");
  const [Countrys] = useRest("Country");
  const [formEnabled, setFormEnabled] = useState(false);

  // country
  useEffect(() => {
    if (geoVal !== null) {
      setCountry(geoVal.data.country.iso_code);
    }
  }, [geoVal]);

  // form input check
  useEffect(() => {
    if (
      !lName.trim() ||
      !fName.trim() ||
      !zip.trim() ||
      !province.trim() ||
      !city.trim() ||
      !address.trim() ||
      !phone.trim() ||
      !country.trim()
    ) {
      setFormEnabled(false);
      return;
    }
    setFormEnabled(true);
  }, [lName, fName, zip, country, province, city, address, phone]);

  const createLocation = (e) => {
    if (e) e.preventDefault();

    setLoad(true);

    const params = {
      Billing: {
        First_Name: fName,
        Last_Name: lName,
        Zip: zip,
        Country__: country,
        Address: address,
        City: city,
        Province: province,
        Contact_Phone: phone,
      },
      seat: currentSeat[0].Seat_Name,
    };

    rest("Catalog/Cart/@:reset", "POST");

    rest(
      `Planner/Event/${event.data.Planner_Event__}:reserveOrder`,
      "POST",
      params
    )
      .then((data) => {
        setLoad(false);
        history.push("/order/" + data.data.Order.Order__);
      })
      .catch((data) => {
        setLoad(false);
        setTimeout(() => {
          error(data.message, {
            position: "top-center",
            autoClose: 3000,
          });
        }, 100);
      });
  };

  useEffect(() => {
    if (event !== null) {
      let filterSeat = "";
      if (hash.seat !== void 0) {
        filterSeat = _.filter(event.data.Planner_Event.Seats, function (o) {
          return o.Planner_Event_Seat__ === hash.seat;
        });
      } else {
        filterSeat = [event.data.Planner_Event.Seats[0]];
      }
      setCurrentSeat(filterSeat);
    }
  }, [event]);

  return (
    <InnerLayout headType="lower" title={t("checkout_title")}>
      <Helmet>
        <title>{`${t("checkout_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("checkout_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("checkout_title")} - ${t("common_title")}`}
        />
      </Helmet>
      <div className={`${styles["content-wrapper"]}`}>
        <div className={styles["content-container"]}>
          {event === null && user === null && <Loading />}
          {event !== null && user !== null && (
            <>
              <Typography className={styles["reserve-title"]} variant="h5">
                {t("checkout_cart_title")}
              </Typography>
              <ReserveList currentSeat={currentSeat} event={event} />
            </>
          )}
        </div>
      </div>
      <div className={`${styles["content-wrapper"]}`}>
        <div className={styles["content-container"]}>
          <Typography className={styles["reserve-title"]} variant="h5">
            {t("checkout_info_title")}
          </Typography>
          <BillingInputs
            fName={fName}
            setFName={setFName}
            lName={lName}
            setLName={setLName}
            zip={zip}
            setZip={setZip}
            province={province}
            setProvince={setProvince}
            city={city}
            setCity={setCity}
            address={address}
            setAddress={setAddress}
            phone={phone}
            setPhone={setPhone}
            country={country}
            setCountry={setCountry}
            geoVal={geoVal}
            Countrys={Countrys}
            formEnabled={formEnabled}
            setFormEnabled={setFormEnabled}
            load={load}
          />
          <div className={styles["reserve-btns"]}>
            <Button
              disabled={!formEnabled || load}
              onClick={() => {
                createLocation();
              }}
            >
              {t("checkout_to_order")}
            </Button>
          </div>
        </div>
      </div>
      {load && <LoadingOverLay />}
    </InnerLayout>
  );
};

export default Reserve;
