import { upload } from "@karpeleslab/klbfw";

export const updateFiles = (files, complete) => {
  upload.onprogress = (d) => {
    if (d.queue.length !== 0) return;
    let allComplete = true;

    d.running.forEach((value) => {
      if (value.status !== "complete") allComplete = false;
      if (allComplete) {
        complete();
      }
    });
  };

  for (const vf in files) {
    if (files[vf].file) {
      upload.append(files[vf].target, files[vf].file, files[vf].param);
      upload.run();
    }
  }
};
