import React from "react";
import { useTranslation } from "react-i18next";

// material ui
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

// style
import styles from "./List.module.scss";

const SubscribeList = ({ memberShip }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Table className={styles["checkout-table"]}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("cart_label_membership")}</TableCell>
                  <TableCell>{t("cart_label_price")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {memberShip.Catalog_Product["Basic.Name"]}
                  </TableCell>
                  <TableCell>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(
                          `fanclub_plan_${memberShip.Catalog_Product[
                            "Basic.Name"
                          ].toLowerCase()}_price`
                        ),
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default SubscribeList;
