import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import Loading from "components/Loading/Loading";
import TextLink from "components/Link/TextLink";
import SquareList from "components/List/SquareList";

// utils

// style
import styles from "../Fanclub.module.scss";

// constant
import { FANCLUB_BOX_PARAMS } from "const/const";

const Box = () => {
  const { t } = useTranslation();

  const [news] = useRest("Membership/@fanclub:cms_search", {
    ...FANCLUB_BOX_PARAMS,
    ...{ page_no: 0 },
    ...{ results_per_page: 4 },
  });

  return (
    <>
      <h2 className={styles["fanclub-title"]}>{t("common_box")}</h2>
      {news === null && <Loading />}
      {news !== null && (
        <SquareList
          type="dark"
          text="MewBox"
          vals={news?.data.data}
          path="fanclub/mewbox"
        />
      )}

      <TextLink type="dark" path="/fanclub/mewbox" />
    </>
  );
};

export default Box;
