import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// utile
import { buildDate } from "utils/BuildDate";

// style
import styles from "./List.module.scss";

const TextList = ({ vals = null, path = "news", type = null }) => {
  const { t } = useTranslation();

  return (
    <div className={styles["list-news"]}>
      {vals.length !== 0 &&
        vals.map((val, index) => {
          return (
            <div className={styles["list-news-item"]} key={index}>
              <Link
                className={`${styles["list-news-item-link"]} ${styles["list-news-item-link-active"]}`}
                to={`/${path}/${val.Slug}`}
              >
                <p
                  className={clsx(styles["list-news-item-title"], {
                    [styles["list-news-item-title-dark"]]: type === "dark",
                  })}
                >
                  {val.Title}
                </p>
                <div
                  className={clsx(styles["list-news-item-date"], {
                    [styles["list-news-item-date-dark"]]: type === "dark",
                  })}
                >
                  {`${buildDate(
                    parseInt(val.Published.unixms),
                    "YYYY.MM.DD"
                  )} / NEWS`}
                </div>
              </Link>
            </div>
          );
        })}
      {vals.length === 0 && (
        <p className={styles["list-news__notfound"]}>
          {t("common_news_notfound")}
        </p>
      )}
    </div>
  );
};

export default TextList;
