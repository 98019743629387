import React, { useEffect, useState } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { rest } from "@karpeleslab/klbfw";
import { loadStripe } from "@stripe/stripe-js";

// component
import LoadingElements from "components/Loading/LoadingElements";
import BillingInputs from "components/Billing/BillingInputs";
import StripeWrapper from "components/Payment/StripeWrapper";

// styles
import styles from "../../MyPage.module.scss";

const BillingForm = ({ billingRefresh }) => {
  // stripe
  const [stripeKey, setStripeKey] = useState(null);
  const [stripeOptions, setStripeOptions] = useState(null);
  const [formEnabled, setFormEnabled] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);

  const [load, setLoad] = useState(false);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [zip, setZip] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [geoVal] = useRest("ThirdParty/Geoip:lookup");
  const [Countrys] = useRest("Country");

  // country
  useEffect(() => {
    if (geoVal !== null) {
      setCountry(geoVal.data.country.iso_code);
    }
  }, [geoVal]);

  // stripe
  useEffect(() => {
    rest("Order/Payment:methodInfo", "POST", { method: "Stripe" }).then(
      (data) => {
        setStripeKey(data.data.Fields.cc_token.attributes.key);
        setStripeOptions(data.data.Fields.cc_token.attributes.options);
      }
    );
  }, []);

  useEffect(() => {
    if (stripeKey !== null && stripeOptions !== null) {
      setStripePromise(
        loadStripe(stripeKey, {
          stripeAccount: stripeOptions.stripe_account,
        })
      );
    }
  }, [stripeKey, stripeOptions]);

  // form input check
  useEffect(() => {
    if (
      !lName.trim() ||
      !fName.trim() ||
      !zip.trim() ||
      !province.trim() ||
      !city.trim() ||
      !address.trim() ||
      !phone.trim() ||
      !country.trim()
    ) {
      setFormEnabled(false);
      return;
    }
    setFormEnabled(true);
  }, [lName, fName, zip, country, province, city, address, phone]);

  return (
    <div>
      <BillingInputs
        fName={fName}
        setFName={setFName}
        lName={lName}
        setLName={setLName}
        zip={zip}
        setZip={setZip}
        province={province}
        setProvince={setProvince}
        city={city}
        setCity={setCity}
        address={address}
        setAddress={setAddress}
        phone={phone}
        setPhone={setPhone}
        country={country}
        setCountry={setCountry}
        geoVal={geoVal}
        Countrys={Countrys}
        formEnabled={formEnabled}
        setFormEnabled={setFormEnabled}
        load={load}
      />
      {stripePromise === null && (
        <LoadingElements className={styles["form-load-elements"]} />
      )}
      {stripePromise !== null && (
        <StripeWrapper
          type="withBilling"
          billingRefresh={billingRefresh}
          fName={fName}
          lName={lName}
          zip={zip}
          province={province}
          city={city}
          address={address}
          phone={phone}
          country={country}
          formEnabled={formEnabled}
          setLoad={setLoad}
          load={load}
        />
      )}
    </div>
  );
};

export default BillingForm;
