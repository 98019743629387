import React from "react";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Slide from "./section/Slide/Slide";
import News from "./section/News/News";
import Live from "./section/Live/Live";
import Logo from "./section/Logo/Logo";
import Profile from "./section/Profile/Profile";
import Discography from "./section/Discography/Discography";
import Fanclub from "./section/Fanclub/Fanclub";
import Shop from "./section/Shop/Shop";
import Movie from "./section/Movie/Movie";
import Spacer from "components/Spacer/Spacer";

const Index = () => {
  return (
    <>
      <InnerLayout>
        <Slide />
        <News />
        <Spacer />
        <Live />
        <Spacer />
        <Logo />
        <Profile />
        <Spacer />
        <Discography />
        <Spacer />
        <Fanclub />
        <Spacer />
        <Shop />
        <Spacer />
        <Movie />
        <Spacer />
      </InnerLayout>
    </>
  );
};

export default Index;
