import React from "react";
import { useTranslation } from "react-i18next";

// style
import styles from "./Fanclub.module.scss";

// img
import imgGold from "assets/img/icon-plane-gold.png";
import imgSilver from "assets/img/icon-plane-silver.png";
import imgBronze from "assets/img/icon-plane-bronze.png";

const PlanMini = () => {
  const { t } = useTranslation();
  return (
    <div className={styles["fanclub-plan-mini"]}>
      <div
        className={`${styles["fanclub-plan-mini-item"]} ${styles["fanclub-plan-mini-item-gold"]}`}
      >
        <img src={imgGold} alt={t("fanclub_plan_gold")} />
        <h3
          className={styles["fanclub-plan-mini-title"]}
          dangerouslySetInnerHTML={{ __html: t("fanclub_plan_gold_mini") }}
        />
        <p
          className={styles["fanclub-plan-mini-price"]}
          dangerouslySetInnerHTML={{ __html: t("fanclub_plan_gold_price") }}
        />
      </div>
      <div
        className={`${styles["fanclub-plan-mini-item"]} ${styles["fanclub-plan-mini-item-silver"]}`}
      >
        <img src={imgSilver} alt={t("fanclub_plan_silver")} />
        <h3
          className={styles["fanclub-plan-mini-title"]}
          dangerouslySetInnerHTML={{ __html: t("fanclub_plan_silver_mini") }}
        />
        <p
          className={styles["fanclub-plan-mini-price"]}
          dangerouslySetInnerHTML={{ __html: t("fanclub_plan_silver_price") }}
        />
      </div>
      <div
        className={`${styles["fanclub-plan-mini-item"]} ${styles["fanclub-plan-mini-item-bronze"]}`}
      >
        <img src={imgBronze} alt={t("fanclub_plan_bronze")} />
        <h3
          className={styles["fanclub-plan-mini-title"]}
          dangerouslySetInnerHTML={{ __html: t("fanclub_plan_bronze_mini") }}
        />
        <p
          className={styles["fanclub-plan-mini-price"]}
          dangerouslySetInnerHTML={{ __html: t("fanclub_plan_bronze_price") }}
        />
      </div>
    </div>
  );
};

export default PlanMini;
