import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// component
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import TextLink from "components/Link/TextLink";

// utils
import { buildDate } from "utils/BuildDate";

// styles
import styles from "./Cms.module.scss";

// img
import DefaultImg from "assets/img/img-blog-detail-default.png";

// const
import { IMG_VARIATION_BLOG_DETAIL } from "const/const";

const DisplayFanclub = ({ article }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const articleDetail = article?.data?.content_cms_entry_data;

  return (
    <>
      {article === null && (
        <div className={styles["cms-load"]}>
          <Loading />
        </div>
      )}
      {article !== null && article !== false && (
        <>
          <Helmet>
            <title>{`${article.data.content_cms_entry_data.Title} - ${t(
              "common_title"
            )}`}</title>
            <meta
              property="og:title"
              content={`${article.data.content_cms_entry_data.Title} - ${t(
                "common_title"
              )}`}
            />
            <meta
              property="og:site_name"
              content={`${article.data.content_cms_entry_data.Title} - ${t(
                "common_title"
              )}`}
            />
          </Helmet>
          <h1 className={styles["blog-title"]}>
            {article.data.content_cms_entry_data.Title}
          </h1>
          <div className={styles["blog-head"]}>
            <div className={styles["blog-date"]}>
              <div className={styles["blog-date-info"]}>
                {buildDate(parseInt(articleDetail.Published.unixms), "YYYY.MM")}
              </div>
              <div>
                {buildDate(parseInt(articleDetail.Published.unixms), "DD")}
              </div>
            </div>

            <div className={styles["blog-img"]}>
              <img
                src={
                  articleDetail.Top_Drive_Item__ === null
                    ? DefaultImg
                    : articleDetail.Top_Drive_Item.Media_Image[
                        IMG_VARIATION_BLOG_DETAIL
                      ]
                }
              />
            </div>
          </div>
          <div
            className={styles["cms-contents"]}
            dangerouslySetInnerHTML={{
              __html: articleDetail.Contents,
            }}
          />
          <TextLink
            onClick={() => {
              history.goBack();
            }}
            text_token="common_btn_back_list"
            type="back"
          />
        </>
      )}
    </>
  );
};

export default DisplayFanclub;
