import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Tabs, Tab } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import SingingList from "components/List/SingingList";

// style
import styles from "./Discography.module.scss";

// constant

const News = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  return (
    <InnerLayout headType="lower" title={t("discography_title")}>
      <Helmet>
        <title>{`${t("discography_sub_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("discography_sub_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("discography_sub_title")} - ${t("common_title")}`}
        />
      </Helmet>
      <div className={`${styles["content-wrapper"]}`}>
        <div className={styles["content-container"]}>
          <Tabs
            className={styles["tabs"]}
            value={value}
            onChange={(e, newValue) => {
              setValue(newValue);
            }}
          >
            <Tab
              className={styles["tabs-item"]}
              label={t("discography_tab_original")}
              id="simple-tab-0"
            />
            <Tab
              className={styles["tabs-item"]}
              label={t("discography_tab_singing")}
              id="simple-tab-1"
            />
          </Tabs>
          <div role="tabpanel" hidden={value !== 0} id="simple-tabpanel-0">
            <SingingList type="original" />
          </div>
          <div role="tabpanel" hidden={value !== 1} id="simple-tabpanel-1">
            <SingingList type="cover" />
          </div>
        </div>
      </div>
    </InnerLayout>
  );
};

export default News;
