import React from "react";
import { useTranslation } from "react-i18next";
//material ui

// components
import ContentTitle from "components/Typography/ContentTitle";
import TextLink from "components/Link/TextLink";
import ShopItems from "components/shopItem/shopItems";

// style
import styles from "./Shop.module.scss";

// img

const Shop = () => {
  const { t } = useTranslation();

  return (
    <div className={`${styles["content-wrapper"]}`}>
      <div className={styles["content-container"]}>
        <ContentTitle>{t("common_shop")}</ContentTitle>
        <ShopItems view={2} paging={false} btns={false} />
        <TextLink path="/shop" />
      </div>
    </div>
  );
};

export default Shop;
