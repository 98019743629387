import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Loading from "components/Loading/Loading";
import TextList from "components/List/TextList";
import Helmet from "components/Helmet/Helmet";
import Pagination from "components/Pagination/Pagination";

// style
import styles from "./News.module.scss";

// constant
import { NEWS_PARAMS } from "const/const";

const News = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pageNum } = useParams();

  //pages
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );

  const [news, setNews] = useRest("Content/Cms/@news:search", {
    ...NEWS_PARAMS,
    ...{ page_no: currentPage },
  });

  const newsRefresh = useRestRefresh("Content/Cms/@news:search", {
    ...NEWS_PARAMS,
    ...{ page_no: currentPage },
  });

  useEffect(() => {
    setNews(null);
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
  }, [location.pathname, pageNum]);

  useEffect(() => {
    if (news === null) {
      newsRefresh();
    }
  }, [currentPage, news, newsRefresh]);

  return (
    <InnerLayout headType="lower" title={t("news_title")}>
      <Helmet>
        <title>{`${t("news_sub_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("news_sub_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("news_sub_title")} - ${t("common_title")}`}
        />
      </Helmet>
      <div className={`${styles["content-wrapper"]}`}>
        <div className={styles["content-container"]}>
          {news === null && <Loading />}
          {news !== null && <TextList vals={news?.data.data} />}
          {news !== null && (
            <Pagination
              setCurrentPage={setCurrentPage}
              defaultPage={currentPage}
              path=""
              count={news?.paging.page_max}
            />
          )}
        </div>
      </div>
    </InnerLayout>
  );
};

export default News;
