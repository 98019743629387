import React from "react";
import { useTranslation } from "react-i18next";

// components
import Sns from "components/Sns/Sns";

// style
import styles from "../Header.module.scss";

// img
import Logo from "assets/img/logo.png";
import Cara from "assets/img/bg-mew.png";

const HeaderTop = () => {
  const { t } = useTranslation();

  return (
    <header className={styles["header-top"]}>
      <p
        className={styles["header-top-text"]}
        dangerouslySetInnerHTML={{ __html: t("common_text") }}
      />
      <div className={styles["header-top-content"]}>
        <h1 className={styles["header-top-logo"]}>
          <img src={Logo} alt={t("common_title")} />
        </h1>
        <span className={styles["header-top-cara"]}>
          <img src={Cara} alt={t("common_title")} />
        </span>
      </div>
      <div className={styles["header-top-sns"]}>
        <Sns />
      </div>
    </header>
  );
};

export default HeaderTop;
