import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// component

// utils

// style
import styles from "../Fanclub.module.scss";

const Nav = () => {
  const { t } = useTranslation();

  return (
    <>
      <ul className={styles["fanclub-nav"]}>
        <li className={styles["fanclub-nav-item"]}>
          <Link to="/fanclub/news">{t("common_news_fanclub")}</Link>
        </li>
        <li className={styles["fanclub-nav-item"]}>
          <Link to="/fanclub/live">{t("common_video")}</Link>
        </li>
        <li className={styles["fanclub-nav-item"]}>
          <Link to="/fanclub/blog">{t("common_blog")}</Link>
        </li>
        <li className={styles["fanclub-nav-item"]}>
          <Link to="/fanclub/gallery">{t("common_gallery")}</Link>
        </li>
        <li className={styles["fanclub-nav-item"]}>
          <Link to="/fanclub/mewbox">{t("common_box")}</Link>
        </li>
      </ul>
    </>
  );
};

export default Nav;
