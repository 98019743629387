import React from "react";
import { useTranslation } from "react-i18next";
//material ui

// components
import ContentTitle from "components/Typography/ContentTitle";
import Paragraph from "components/Typography/Paragraph";
import TextLink from "components/Link/TextLink";

// style
import styles from "./Fanclub.module.scss";

// img
import iconCat from "assets/img/icon-cat.png";

const Fanclub = () => {
  const { t } = useTranslation();

  return (
    <div className={`${styles["content-wrapper"]}`}>
      <div className={styles["content-container"]}>
        <ContentTitle>{t("common_fanclub_line")}</ContentTitle>
        <Paragraph className={styles["fanclub-text"]}>
          {t("top_fanclub_text")}
        </Paragraph>
        <div className={styles["fanclub-icon"]}>
          <img src={iconCat} alt={t("common_fanclub")} />
        </div>
        <TextLink path="/fanclub" text_token={t("common_fanclub")} />
      </div>
    </div>
  );
};

export default Fanclub;
