import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// utile
import { buildDate } from "utils/BuildDate";

// style
import styles from "./List.module.scss";

const EventList = ({ vals = null, path = "live" }) => {
  const { t } = useTranslation();

  return (
    <div className={styles["list-news"]}>
      {vals === null && (
        <p className={styles["list-news__notfound"]}>
          {t("common_live_notfound")}
        </p>
      )}
      {vals !== null &&
        vals.length !== 0 &&
        vals.map((val, index) => {
          return (
            <div className={styles["list-news-item"]} key={index}>
              <Link
                className={`${styles["list-news-item-link"]} ${styles["list-news-item-link-active"]}`}
                to={`/${path}/${val.Planner_Schedule__}`}
              >
                <p className={styles["list-news-item-title"]}>
                  {val.Content_Cms_Entry_Data.Title}
                </p>
                <div className={styles["list-news-item-date"]}>
                  {buildDate(
                    parseInt(
                      val.Planner_Event__ === null
                        ? val.Date.unixms
                        : val.Planner_Event.Event_Date.unixms
                    ),
                    "YYYY.MM.DD"
                  )}
                </div>
              </Link>
            </div>
          );
        })}
      {vals !== null && vals.length === 0 && (
        <p className={styles["list-news__notfound"]}>
          {t("common_live_notfound")}
        </p>
      )}
    </div>
  );
};

export default EventList;
