import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { rest } from "@karpeleslab/klbfw";
import { error } from "components/Toast/Toast";
import clsx from "clsx";

//material ui

// component
import Button from "components/Btns/Btn";

// img
import SoldOut from "assets/img/img-sold-out.png";
import DefaultImg from "assets/img/img-blog-thumd-default.png";

// style
import styles from "./shopItem.module.scss";

// constant

const ShopItem = ({ val = null, cartRefresh = null, btns = true }) => {
  const [quantity, setQuantity] = useState("1");
  const [quantityMax, setQuantityMax] = useState(5);
  const history = useHistory();

  const addToCart = () => {
    const query = {
      product: val.Catalog_Product__,
      quantity: quantity,
    };

    rest("Catalog/Cart/@:add", "POST", query)
      .then(() => {
        cartRefresh();
        history.push("/cart");
      })
      .catch(() => {
        cartRefresh();
        error("商品の追加に失敗しました。", {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (val.Deliverable.Stock < quantityMax) {
      setQuantityMax(parseInt(val.Deliverable.Stock));
    }
  }, [val]);

  return (
    <>
      <div className={styles["shop-item"]}>
        <figure className={styles["shop-item-img"]}>
          {val.Deliverable.Stock === 0 && (
            <img
              className={styles["shop-item-img-soldout"]}
              src={SoldOut}
              alt="SOLD OUT"
            />
          )}
          <img
            className={styles["shop-item-img-product"]}
            src={val.Image.main === void 0 ? DefaultImg : val.Image.main[0].Url}
            alt={val["Basic.Name"]}
          />
        </figure>
        <div className={styles["shop-item-head"]}>
          <p className={styles["shop-item-name"]}>{val["Basic.Name"]}</p>
          <p className={styles["shop-item-price"]}>
            {val.Public_Price.tax.display}（税込）
          </p>
        </div>
        <p className={styles["shop-item-phrase"]}>
          {val["Description.CatchPhrase"]}
        </p>
        <p
          className={styles["shop-item-short"]}
          dangerouslySetInnerHTML={{
            __html: val["Description.Short"].replace(/\n/g, "<br />"),
          }}
        ></p>
        {btns && (
          <div
            className={clsx(styles["shop-item-select-wrapper"], {
              [styles["shop-item-select-wrapper-soldout"]]:
                val.Deliverable.Stock === 0,
            })}
          >
            {val.Deliverable.Use_Stock === false && (
              <div className={styles["shop-item-select-quantity"]}>
                <select
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                  value={quantity}
                >
                  {(() => {
                    const items = [];
                    for (let i = 1; i <= 5; i++) {
                      items.push(
                        <option key={i} value={i}>
                          {i}
                        </option>
                      );
                    }
                    return items;
                  })()}
                </select>
                個
              </div>
            )}
            {val.Deliverable.Use_Stock === true && (
              <>
                {val.Deliverable.Stock > 0 && (
                  <div className={styles["shop-item-select-quantity"]}>
                    <select
                      onChange={(e) => {
                        setQuantity(e.target.value);
                      }}
                      value={quantity}
                    >
                      {(() => {
                        const items = [];
                        for (let i = 1; i <= quantityMax; i++) {
                          items.push(
                            <option key={i} value={i}>
                              {i}
                            </option>
                          );
                        }
                        return items;
                      })()}
                    </select>
                    個
                  </div>
                )}
              </>
            )}

            {val.Deliverable.Use_Stock === false && (
              <Button
                onClick={() => {
                  addToCart();
                }}
                iconPosition="noting"
              >
                カートに入れる
              </Button>
            )}
            {val.Deliverable.Use_Stock === true && (
              <>
                {val.Deliverable.Stock === 0 && (
                  <Button iconPosition="noting" disabled={true}>
                    売り切れ
                  </Button>
                )}
                {val.Deliverable.Stock > 0 && (
                  <Button
                    onClick={() => {
                      addToCart();
                    }}
                    iconPosition="noting"
                  >
                    カートに入れる
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ShopItem;
