import React, { useState } from "react";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// material ui

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import OrderList from "components/List/OrderList";
import StripeWrapper from "components/Payment/StripeWrapper";
import AozoraNetWrapper from "components/Payment/AozoraNet";
import Btn from "components/Btns/Btn";

// style
import styles from "./Order.module.scss";

const Order = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [orderProcess] = useRest(`Order/${slug}:process`);
  const orderRefresh = useRestRefresh(`Order/${slug}:process`);
  const cartRefresh = useRestRefresh("Catalog/Cart/@");
  const [user] = useRest("User:get");
  const [orderComp, setOrderComp] = useState(false);

  const [load, setLoad] = useState(false);

  return (
    <InnerLayout headType="lower" title={t("checkout_title")}>
      <Helmet>
        <title>{`${t("checkout_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("checkout_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("checkout_title")} - ${t("common_title")}`}
        />
      </Helmet>
      {orderComp && (
        <div className={`${styles["content-wrapper"]}`}>
          <div className={styles["content-container"]}>
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "#BFACD1" }}>{t("subscribe_content_comp")}</p>
              <Btn to="/">{t("common_btn_top")}</Btn>
            </div>
          </div>
        </div>
      )}
      {!orderComp && (
        <>
          <div className={`${styles["content-wrapper"]}`}>
            <div className={styles["content-container"]}>
              {orderProcess !== null && user !== null && (
                <>
                  <OrderList order={orderProcess} />
                </>
              )}
            </div>
          </div>
          {orderProcess !== null &&
            orderProcess.data.order.Status !== "completed" &&
            orderProcess.data.methods.Stripe !== void 0 && (
              <div className={`${styles["content-wrapper"]}`}>
                <div className={styles["content-container"]}>
                  <StripeWrapper
                    type="order"
                    setLoad={setLoad}
                    load={load}
                    orderProcess={orderProcess}
                    cartRefresh={cartRefresh}
                    setOrderComp={setOrderComp}
                  />
                </div>
              </div>
            )}
        </>
      )}
      {!orderComp &&
        orderProcess !== null &&
        orderProcess.data.order.Status !== "completed" &&
        orderProcess.data.methods.AozoraNet !== void 0 && (
          <div className={`${styles["content-wrapper"]}`}>
            <div className={styles["content-container"]}>
              <AozoraNetWrapper
                orderProcess={orderProcess}
                cartRefresh={cartRefresh}
                orderRefresh={orderRefresh}
                setOrderComp={setOrderComp}
              />
            </div>
          </div>
        )}
    </InnerLayout>
  );
};

export default Order;
