import React, { useState } from "react";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

// component
import InputForm from "components/Forms/Input";
import Button from "components/Btns/Btn";
import Password from "components/Password/Password";
import Loading from "components/Loading/Loading";
import { error, success } from "components/Toast/Toast";

// styles
import styles from "../MyPage.module.scss";

const User = () => {
  const { t } = useTranslation();
  const [user] = useRest("User:get");
  const userRefresh = useRestRefresh("User:get");
  const [email, setEmail] = useState(user?.data.Email);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);

  const submitForm = (password) => {
    setLoad(true);

    const data = {
      email: email,
      current_password: password,
    };

    rest("User/@:setEmail", "POST", data)
      .then(() => {
        setOpen(false);
        setLoad(false);
        success("toast_success_text", true, {
          position: "top-center",
          autoClose: 1500,
          onClose: () => {
            userRefresh();
          },
        });
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  if (user === null) {
    return <Loading />;
  }

  return (
    <div>
      <InputForm
        type="email"
        value={email}
        name="user-email"
        label={t("mypage_label_user")}
        placeholder={t("input_field_placeholder", {
          text: t("mypage_label_user"),
        })}
        handleChange={(event) => {
          setEmail(event.target.value);
        }}
        required={true}
        disabled={load}
      />
      <div className={styles["mypage-btns"]}>
        <Button
          onClick={() => {
            setOpen(true);
          }}
          iconPosition="noting"
          disabled={user.data.Email === email}
        >
          {t("common_save")}
        </Button>
      </div>
      <Password
        disabled={load}
        setOpen={setOpen}
        open={open}
        submit={submitForm}
      />
    </div>
  );
};

export default User;
