import React from "react";
import ReactHelmet from "react-helmet";

const Helmet = (props) => {
  return (
    <ReactHelmet>
      <meta charSet="utf-8" />
      {props.children}
    </ReactHelmet>
  );
};

export default Helmet;
