export const IMG_VARIATION = "format=jpeg&strip&scale_crop=374x247";
export const IMG_VARIATION_INFOMATION = "format=jpeg&strip&scale_crop=400x300";

// SNS
export const SNS_LIST = [
  {
    brand: "twitter",
    url: "https://twitter.com/Mew_tcmp",
  },
  {
    brand: "youtube",
    url: "https://www.youtube.com/c/MewProject/featured",
  },
];

// SlickOptions
export const SlickOptions = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  centerMode: true,
  centerPadding: "10px",
  arrows: false,
};

// detail
export const IMG_VARIATION_INFOMATION_DETAIL =
  "format=jpeg&strip&scale_crop=630x630";

// blog
export const IMG_VARIATION_BLOG_THUMB = "format=jpeg&strip&scale_crop=290x290";

export const IMG_VARIATION_BLOG_DETAIL = "format=jpeg&strip&scale_crop=500x500";

// singing list
export const SINGING_LIST = [
  {
    name: "☁︎☔︎☀︎",
    url: "https://www.youtube.com/watch?v=grVWTB5cN1Q",
    date: "2023/04/10",
    type: "original",
  },
  {
    name: "ラヴィ",
    url: "https://www.youtube.com/watch?v=vECRTqqJW5Y",
    date: "2023/02/10",
    type: "cover",
  },
  {
    name: "KICK BACK",
    url: "https://www.youtube.com/watch?v=rjlR-D-AXeI",
    date: "2023/01/31",
    type: "cover",
  },
  {
    name: "スナネコのうた",
    url: "https://www.youtube.com/watch?v=MrQt8DN0LLY",
    date: "2022/12/22",
    type: "cover",
  },
  {
    name: "バグ",
    url: "https://www.youtube.com/watch?v=vnaxoPkq4rc",
    date: "2022/10/31",
    type: "cover",
  },
  {
    name: "モノスコープ",
    url: "https://www.youtube.com/watch?v=8oyCT8SMjrQ",
    date: "2022/10/28",
    type: "cover",
  },
  {
    name: "ド屑",
    url: "https://www.youtube.com/watch?v=eIgmLvW_6dM",
    date: "2022/10/12",
    type: "cover",
  },
  {
    name: "ラブカ？",
    url: "https://www.youtube.com/watch?v=UmY1nBWVfLc",
    date: "2022/09/20",
    type: "cover",
  },
  {
    name: "QUEEN",
    url: "https://www.youtube.com/watch?v=8tJdRBghIrQ",
    date: "2022/08/31",
    type: "cover",
  },
  {
    name: "魔法少女とチョコレゐト",
    url: "https://www.youtube.com/watch?v=t954BmZ59g0",
    date: "2022/08/25",
    type: "cover",
  },
  {
    name: "なにやってもうまくいかない",
    url: "https://www.youtube.com/watch?v=iqcS7UD7aWA",
    date: "2022/07/31",
    type: "cover",
  },
  {
    name: "邪魔",
    url: "https://www.youtube.com/watch?v=wtdWVL6GZpA",
    date: "2022/07/01",
    type: "cover",
  },
  {
    name: "millennium parade × Belle - U",
    url: "https://www.youtube.com/watch?v=xFt6-WmNLfk",
    date: "2022/06/23",
    type: "cover",
  },
  {
    name: "それがあなたの幸せとしても",
    url: "https://www.youtube.com/watch?v=7omf3RYjP5E",
    date: "2022/05/24",
    type: "cover",
  },
  {
    name: "きゅうくらりん",
    url: "https://www.youtube.com/watch?v=EUZp3gmNqRM",
    date: "2022/03/31",
    type: "cover",
  },
  {
    name: "キャットラビング",
    url: "https://www.youtube.com/watch?v=lCsZQsBOWzY",
    date: "2022/02/22",
    type: "cover",
  },
  {
    name: "フクロウ ～フクロウが知らせる客が来たと～",
    url: "https://www.youtube.com/watch?v=o9c2Uiv1b5k",
    date: "2022/02/05",
    type: "cover",
  },
  {
    name: "神っぽいな",
    url: "https://www.youtube.com/watch?v=cx8OdDaqkTY",
    date: "2022/01/28",
    type: "cover",
  },
  {
    name: "G4L - Giga",
    url: "https://www.youtube.com/watch?v=s_erchuyqRA",
    date: "2021/12/31",
    type: "cover",
  },
  {
    name: "回る空うさぎ - Orangestar",
    url: "https://www.youtube.com/watch?v=gvoRQxsVWD8",
    date: "2021/11/26",
    type: "cover",
  },
  {
    name: "マーシャル・マキシマイザー - 柊マグネタイト",
    url: "https://www.youtube.com/watch?v=ED-TV8ZIkvI",
    date: "2021/11/12",
    type: "cover",
  },
  {
    name: "我儘姫 - ふじを",
    url: "https://www.youtube.com/watch?v=XurbXRhj-hgo",
    date: "2021/10/31",
    type: "cover",
  },
  {
    name: "大人 - カンザキイオリ",
    url: "https://www.youtube.com/watch?v=tgXFJTArf0I",
    date: "2021/10/24",
    type: "cover",
  },
  {
    name: "ギラギラ - Ado",
    url: "https://www.youtube.com/watch?v=mbsLyP_ZDxo",
    date: "2021/10/02",
    type: "cover",
  },
  {
    name: "世田谷ナイトサファリ - みきとP",
    url: "https://www.youtube.com/watch?v=NLZJ4jwDkDY",
    date: "2021/09/13",
    type: "cover",
  },
  {
    name: "命の行方 - DUSTCELL",
    url: "https://www.youtube.com/watch?v=cSTnoaS8_Ok",
    date: "2021/08/29",
    type: "cover",
  },
  {
    name: "VIVID VICE - Who-ya Extended",
    url: "https://www.youtube.com/watch?v=PvW0Xx7_DQQ",
    date: "2021/08/20",
    type: "cover",
  },
  {
    name: "flos - R Sound Design",
    url: "https://www.youtube.com/watch?v=tgjoVCKUzdQ",
    date: "2021/08/12",
    type: "cover",
  },
  {
    name: "フォニイ / phony - ツミキ",
    url: "https://www.youtube.com/watch?v=EzH09zh2JcY",
    date: "2021/07/28",
    type: "cover",
  },
  {
    name: "踊 - Ado",
    url: "https://www.youtube.com/watch?v=pNsfuX_J_Jc",
    date: "2020.07.02",
    type: "cover",
  },
  {
    name: "ドライフラワー - 優里",
    url: "https://www.youtube.com/watch?v=WmOyVn0lNaA",
    date: "2021.06.12",
    type: "cover",
  },
  {
    name: "言って。 - ヨルシカ",
    url: "https://www.youtube.com/watch?v=j0afcMg_xag",
    date: "2021.04.30",
    type: "cover",
  },
  {
    name: "ジャンキーナイトタウンオーケストラ - すりぃ",
    url: "https://www.youtube.com/watch?v=LMcrJT7Hz8s",
    date: "2021.04.23",
    type: "cover",
  },
  {
    name: "キュートなカノジョ - syudou",
    url: "https://www.youtube.com/watch?v=Lu0xAiPaeCU",
    date: "2021.03.31",
    type: "cover",
  },
  {
    name: "グッバイ宣言 - Chinozo",
    url: "https://www.youtube.com/watch?v=V2f0N1lgFs0",
    date: "2021.02.21",
    type: "cover",
  },
  {
    name: "うっせぇわ - Ado",
    url: "https://www.youtube.com/watch?v=NNPM6xvIa64&t=7s",
    date: "2021.01.31",
    type: "cover",
  },
  {
    name: "ヴィラン",
    url: "https://www.youtube.com/watch?v=abrYpWn8UUE",
    date: "2020.12.31",
    type: "cover",
  },
  {
    name: "フラジール",
    url: "https://www.youtube.com/watch?v=Xwvt32mpybM",
    date: "2020.11.14",
    type: "cover",
  },
  {
    name: "Happy Halloween / Jyunky",
    url: "https://www.youtube.com/watch?v=pRPx3pvM0B4",
    date: "2020.10.31",
    type: "cover",
  },
  {
    name: "花に亡霊 / ヨルシカ",
    url: "https://www.youtube.com/watch?v=uGF78gqmZ9Q",
    date: "2020.09.25",
    type: "cover",
  },
  {
    name: "KING / Kanaria",
    url: "https://www.youtube.com/watch?v=8gUl25SIh7M",
    date: "2020.09.15",
    type: "cover",
  },
  {
    name: "だから僕は音楽を辞めた（ヨルシカ）-Arrange ver.- ",
    url: "https://www.youtube.com/watch?v=yKed7wyNNJQ",
    date: "2020.08.28",
    type: "cover",
  },
  {
    name: "輪廻転生 / まふまふ",
    url: "https://www.youtube.com/watch?v=b2kI-WQ-V-s&t=61s",
    date: "2020.08.16",
    type: "cover",
  },
  {
    name: "ボッカデラベリタ / 柊キライ",
    url: "https://www.youtube.com/watch?v=N0-EXwvQyt0",
    date: "2020.08.01",
    type: "cover",
  },
  {
    name: "夜に駆ける / YOASOBI",
    url: "https://www.youtube.com/watch?v=OGtclc8uH6c",
    date: "2020.07.22",
    type: "cover",
  },
];

export const NEWS_PAGE_COUNT_TOP = 4;
export const NEWS_PAGE_COUNT = 10;
export const NEWS_PAGE_COUNT_TEST = 1;

export const HISTORY_PAGE_COUNT = 10;

export const BLOG_PAGE_COUNT = 16;
export const BLOG_DETAIL_PAGE_COUNT = 4;

// News Params
export const NEWS_PARAMS_TOP = {
  results_per_page: NEWS_PAGE_COUNT_TOP,
  sort: "published:desc",
};

export const NEWS_PARAMS = {
  results_per_page: NEWS_PAGE_COUNT,
  sort: "published:desc",
};

// Live params
export const LIVE_PARAMS_TOP = {
  results_per_page: NEWS_PAGE_COUNT_TOP,
  sort: {
    Date: "DESC",
  },
  image_variation: IMG_VARIATION_INFOMATION_DETAIL,
  Visible: "Y",
};

export const LIVE_PARAMS = {
  results_per_page: NEWS_PAGE_COUNT,
  sort: {
    Date: "DESC",
  },
  image_variation: IMG_VARIATION_INFOMATION_DETAIL,
  Visible: "Y",
};

// fanclub
// ** blog list
export const FANCLUB_BLOG_PARAMS = {
  results_per_page: BLOG_PAGE_COUNT,
  sort: "published:desc",
  image_variation: IMG_VARIATION_BLOG_THUMB,
  page_no: 1,
  query: {
    tag: "fanclub:blog",
  },
};

// ** blog detail List
export const FANCLUB_DETAIL_BLOG_PARAMS = {
  results_per_page: BLOG_DETAIL_PAGE_COUNT,
  sort: "published:desc",
  image_variation: IMG_VARIATION_BLOG_THUMB,
  query: {
    tag: "fanclub:blog",
  },
};

// ** news list
export const FANCLUB_NEWS_PARAMS = {
  results_per_page: NEWS_PAGE_COUNT,
  sort: "published:desc",
  query: {
    tag: "fanclub:news",
  },
};

// ** gallery list
export const FANCLUB_GALLERY_PARAMS = {
  results_per_page: BLOG_PAGE_COUNT,
  sort: "published:desc",
  image_variation: IMG_VARIATION_BLOG_THUMB,
  query: {
    tag: "fanclub:gallery",
  },
};

// ** MewBox list
export const FANCLUB_BOX_PARAMS = {
  results_per_page: BLOG_PAGE_COUNT,
  sort: "published:desc",
  image_variation: IMG_VARIATION_BLOG_THUMB,
  query: {
    tag: "fanclub:box",
  },
};

export const BILLING_LABEL = "mew project payment method";

export const STRIPE_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#BFACD1",
      color: "#BFACD1",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#BFACD1" },
      "::placeholder": { color: "#BFACD1" },
    },
    invalid: {
      iconColor: "#ff0000",
      color: "#ff0000",
    },
  },
};

export const CATALOG_PRODUCT = "cpr-46i6mk-ukxf-h7xp-jqpt-h6sn55ci";
