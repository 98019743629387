import React, { useState } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import BillingForm from "./Billing/BillingForm";
import DisplayBilling from "./Billing/DisplayBilling";
import EditBilling from "./Billing/EditBilling";
import Loading from "components/Loading/Loading";

const Billing = () => {
  const [billing] = useRest("User/@/Billing", true);
  const [edit, setEdit] = useState(false);

  const billingRefresh = () => {
    window.scrollTo(0, 0);
    window.location.reload();
  };

  if (billing === null) {
    return <Loading />;
  }

  return (
    <div>
      {billing.data.length === 0 && (
        <BillingForm billingRefresh={billingRefresh} />
      )}
      {!edit && (
        <DisplayBilling
          billing={billing}
          billingRefresh={billingRefresh}
          setEdit={setEdit}
        />
      )}
      {edit && (
        <EditBilling
          billing={billing}
          setEdit={setEdit}
          billingRefresh={billingRefresh}
        />
      )}
    </div>
  );
};

export default Billing;
