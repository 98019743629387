import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useTranslation } from "react-i18next";
import _ from "lodash";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import Plan from "components/Fanclub/Plan";
import Nav from "views/Fanclub/section/nav";
import SubscribeList from "components/List/SubscribeList";
import InputForm from "components/Forms/Input";
import SelectInput from "components/Forms/SelectInput";
import Country from "components/Forms/Country";
import StripeWrapper from "components/Payment/StripeWrapper";
import PlanCard from "components/Fanclub/PlanCard";

// utils

// style
import styles from "./Subscribe.module.scss";
import fanclub from "views/Fanclub/Fanclub.module.scss";

// const

const Subscribe = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [plan] = useRest("Membership/@fanclub");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const location = useLocation();
  const [subscribeComp, setSubscribeComp] = useState(false);

  useEffect(() => {
    if (plan !== null) {
      const selected = _.find(plan.data.Membership_Plan, {
        Membership_Plan__: slug,
      });
      setSelectedPlan(selected);
    }
  }, [plan, location]);

  // load
  const [load, setLoad] = useState(false);

  // form
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [zip, setZip] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [geoVal] = useRest("ThirdParty/Geoip:lookup");
  const [Countrys] = useRest("Country");
  const [formEnabled, setFormEnabled] = useState(false);
  const [birth, serBirth] = useState("");
  const [gender, setGender] = useState("");

  // country
  useEffect(() => {
    if (geoVal !== null) {
      setCountry(geoVal.data.country.iso_code);
    }
  }, [geoVal]);

  // form input check
  useEffect(() => {
    if (
      !birth.trim() ||
      !gender.trim() ||
      !lName.trim() ||
      !fName.trim() ||
      !zip.trim() ||
      !province.trim() ||
      !city.trim() ||
      !address.trim() ||
      !phone.trim() ||
      !country.trim()
    ) {
      setFormEnabled(false);
      return;
    }
    setFormEnabled(true);
  }, [lName, fName, zip, country, province, city, address, phone]);

  // gender
  const genderArray = [
    { label: t("mypage_label_user_gender_select"), val: "" },
    { label: t("mypage_label_user_gender_select_male"), val: "M" },
    { label: t("mypage_label_user_gender_select_female"), val: "F" },
  ];

  return (
    <>
      <InnerLayout headType="white" title={t("common_fanclub")}>
        <Helmet>
          <title>{`${t("common_fanclub")} - ${t("common_title")}`}</title>
          <meta
            property="og:title"
            content={`${t("common_fanclub")} - ${t("common_title")}`}
          />
          <meta
            property="og:site_name"
            content={`${t("common_fanclub")} - ${t("common_title")}`}
          />
        </Helmet>
        {subscribeComp && (
          <>
            <div className={`${styles["content-wrapper"]}`}>
              <div className={styles["content-container"]}>
                <p className={styles["subscribe-comp"]}>
                  {t("subscribe_content_comp")}
                </p>
              </div>
            </div>
          </>
        )}
        {!subscribeComp && (
          <>
            {slug === void 0 && (
              <>
                <Nav />
                <h2 className={fanclub["fanclub-plane-title"]}>
                  {t("fanclub_plane_title")}
                </h2>
                <Plan />
              </>
            )}
            {slug !== void 0 && selectedPlan !== null && (
              <>
                <h2 className={styles["subscribe-plane-title"]}>
                  {t("fanclub_plane_title")}
                </h2>
                <div
                  className={`${styles["content-wrapper"]} ${styles["content-wrapper-fanclub"]}`}
                >
                  <div className={styles["content-container"]}>
                    <PlanCard val={selectedPlan} index="1" type="subscribe" />
                    <SubscribeList memberShip={selectedPlan} />
                  </div>
                </div>
                <h2 className={styles["subscribe-plane-title"]}>
                  {t("subscribe_info_payment")}
                </h2>
                <div
                  className={`${styles["content-wrapper"]} ${styles["content-wrapper-fanclub"]}`}
                >
                  <div className={styles["content-container"]}>
                    <InputForm
                      type="date"
                      value={birth}
                      name="user-birth"
                      label={t("mypage_label_user_birth")}
                      placeholder={t("input_field_placeholder", {
                        text: t("mypage_label_user_birth"),
                      })}
                      handleChange={(event) => {
                        serBirth(event.target.value);
                      }}
                      disabled={load}
                      required={true}
                    />
                    <SelectInput
                      name="user-gender"
                      label={t("mypage_label_user_gender")}
                      value={gender}
                      array={genderArray}
                      handleChange={(event) => {
                        setGender(event.target.value);
                      }}
                      disabled={load}
                      required={true}
                    />
                    <InputForm
                      type="text"
                      value={fName}
                      name="user-fname"
                      label={t("mypage_billing_fname")}
                      placeholder={t("input_field_placeholder", {
                        text: t("mypage_billing_fname"),
                      })}
                      handleChange={(event) => {
                        setFName(event.target.value);
                      }}
                      required={true}
                      disabled={load}
                    />
                    <InputForm
                      type="text"
                      value={lName}
                      name="user-lname"
                      label={t("mypage_billing_lname")}
                      placeholder={t("input_field_placeholder", {
                        text: t("mypage_billing_lname"),
                      })}
                      handleChange={(event) => {
                        setLName(event.target.value);
                      }}
                      required={true}
                      disabled={load}
                    />
                    <InputForm
                      type="text"
                      value={zip}
                      name="user-zip"
                      label={t("mypage_billing_zip")}
                      placeholder={t("input_field_placeholder", {
                        text: t("mypage_billing_zip"),
                      })}
                      handleChange={(event) => {
                        setZip(event.target.value);
                      }}
                      required={true}
                      disabled={load}
                    />
                    <InputForm
                      type="text"
                      value={province}
                      name="user-province"
                      label={t("mypage_billing_province")}
                      placeholder={t("input_field_placeholder", {
                        text: t("mypage_billing_province"),
                      })}
                      handleChange={(event) => {
                        setProvince(event.target.value);
                      }}
                      required={true}
                      disabled={load}
                    />
                    <InputForm
                      type="text"
                      value={city}
                      name="user-city"
                      label={t("mypage_billing_city")}
                      placeholder={t("input_field_placeholder", {
                        text: t("mypage_billing_city"),
                      })}
                      handleChange={(event) => {
                        setCity(event.target.value);
                      }}
                      required={true}
                      disabled={load}
                    />
                    <InputForm
                      type="text"
                      value={address}
                      name="user-address"
                      label={t("mypage_billing_address")}
                      placeholder={t("input_field_placeholder", {
                        text: t("mypage_billing_address"),
                      })}
                      handleChange={(event) => {
                        setAddress(event.target.value);
                      }}
                      required={true}
                      disabled={load}
                    />
                    <InputForm
                      type="tel"
                      value={phone}
                      name="user-phone"
                      label={t("mypage_billing_phone")}
                      placeholder={t("input_field_placeholder", {
                        text: t("mypage_billing_phone"),
                      })}
                      handleChange={(event) => {
                        setPhone(event.target.value);
                      }}
                      required={true}
                      disabled={load}
                    />
                    <Country
                      name="user-country"
                      label={t("mypage_billing_country")}
                      value={country}
                      array={Countrys?.data}
                      handleChange={(event) => {
                        setCountry(event.target.value);
                      }}
                      required={true}
                      disabled={load}
                    />
                    <StripeWrapper
                      type="fanclub"
                      fName={fName}
                      lName={lName}
                      zip={zip}
                      province={province}
                      city={city}
                      address={address}
                      phone={phone}
                      country={country}
                      formEnabled={formEnabled}
                      selectedPlan={selectedPlan}
                      birth={birth}
                      gender={gender}
                      setLoad={setLoad}
                      load={load}
                      setSubscribeComp={setSubscribeComp}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </InnerLayout>
    </>
  );
};

export default Subscribe;
