import React from "react";
import { useTranslation } from "react-i18next";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import Plan from "components/Fanclub/Plan";
import Nav from "./section/nav";

// utils

// style
import styles from "./Fanclub.module.scss";

// const

const FanclubPlan = () => {
  const { t } = useTranslation();

  return (
    <>
      <InnerLayout headType="white" title={t("common_fanclub")}>
        <Helmet>
          <title>{`${t("common_fanclub")} - ${t("common_title")}`}</title>
          <meta
            property="og:title"
            content={`${t("common_fanclub")} - ${t("common_title")}`}
          />
          <meta
            property="og:site_name"
            content={`${t("common_fanclub")} - ${t("common_title")}`}
          />
        </Helmet>
        <Nav />
        <h2 className={styles["fanclub-plane-title"]}>
          {t("fanclub_plane_title")}
        </h2>
        <Plan />
      </InnerLayout>
    </>
  );
};

export default FanclubPlan;
