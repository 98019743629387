import React from "react";
import { useTranslation } from "react-i18next";

//material ui

// components
import ContentTitle from "components/Typography/ContentTitle";
import SingingList from "components/List/SingingList";
import TextLink from "components/Link/TextLink";

// style
import styles from "./Discography.module.scss";

// img

const Discography = () => {
  const { t } = useTranslation();

  return (
    <div className={`${styles["content-wrapper"]}`}>
      <div className={styles["content-container"]}>
        <ContentTitle>{t("common_disco_line")}</ContentTitle>
        <SingingList num={2} />
        <TextLink path="/discography" />
      </div>
    </div>
  );
};

export default Discography;
