import React from "react";
import { useTranslation } from "react-i18next";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";

// utils

// style
import styles from "./Guideline.module.scss";

const Guideline = () => {
  const { t } = useTranslation();

  return (
    <>
      <InnerLayout headType="lower" title={t("common_guideline")}>
        <Helmet>
          <title>{`${t("common_guideline")} - ${t("common_title")}`}</title>
          <meta
            property="og:title"
            content={`${t("common_guideline")} - ${t("common_title")}`}
          />
          <meta
            property="og:site_name"
            content={`${t("common_guideline")} - ${t("common_title")}`}
          />
        </Helmet>
        <div className={`${styles["content-wrapper"]}`}>
          <div className={styles["content-container"]}>
            <h2 className={styles["guideline-title"]}>
              {t("guideline_title_guideline")}
            </h2>
            <p
              className={styles["guideline-text"]}
              dangerouslySetInnerHTML={{
                __html: t("guideline_text_guideline"),
              }}
            />
            <h2 className={styles["guideline-title"]}>
              {t("guideline_title_derivative")}
            </h2>
            <p
              className={styles["guideline-text"]}
              dangerouslySetInnerHTML={{
                __html: t("guideline_text_derivative"),
              }}
            />
            <h2 className={styles["guideline-title"]}>
              {t("guideline_title_illust")}
            </h2>
            <p
              className={styles["guideline-text"]}
              dangerouslySetInnerHTML={{ __html: t("guideline_text_illust") }}
            />
            <h2 className={styles["guideline-title"]}>
              {t("guideline_title_movie")}
            </h2>
            <p
              className={styles["guideline-text"]}
              dangerouslySetInnerHTML={{ __html: t("guideline_text_movie") }}
            />
            <h2 className={styles["guideline-title"]}>
              {t("guideline_title_design")}
            </h2>
            <p
              className={styles["guideline-text"]}
              dangerouslySetInnerHTML={{ __html: t("guideline_text_design") }}
            />
            <h2 className={styles["guideline-title"]}>
              {t("guideline_title_rights")}
            </h2>
            <p
              className={styles["guideline-text"]}
              style={{ whiteSpace: "pre-line" }}
            >
              {t("guideline_text_rights")}
            </p>
            <h2 className={styles["guideline-title"]}>
              {t("guideline_title_creators")}
            </h2>
            <p
              className={styles["guideline-text"]}
              style={{ whiteSpace: "pre-line" }}
            >
              {t("guideline_text_creator_takehana")}
            </p>
            <p
              className={styles["guideline-text"]}
              style={{ whiteSpace: "pre-line" }}
            >
              {t("guideline_text_creator_umi")}
            </p>
            <p
              className={styles["guideline-text"]}
              dangerouslySetInnerHTML={{ __html: t("guideline_text_etc") }}
            />
          </div>
        </div>
      </InnerLayout>
    </>
  );
};

export default Guideline;
