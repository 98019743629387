import React from "react";
import { rest } from "@karpeleslab/klbfw";
import { useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { useTranslation } from "react-i18next";

// material ui
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

// component
import Button from "components/Btns/Btn";
import { error, success } from "components/Toast/Toast";

// style
import styles from "./List.module.scss";

const CartList = ({ cart, deleteBtn = false }) => {
  const { t } = useTranslation();
  const cartRefresh = useRestRefresh("Catalog/Cart/@");

  const removeProduct = (product, name) => {
    const query = {
      key: product,
      quantity: 0,
    };

    rest("Catalog/Cart/@:setQuantity", "POST", query)
      .then(() => {
        success(t("toast_item_remove", { name: name }), {
          position: "top-center",
          autoClose: 1500,
        });
        cartRefresh();
      })
      .catch(() => {
        error(t("toast_item_remove_error", { name: name }), {
          position: "top-center",
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Table className={styles["checkout-table"]}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("cart_label_name")}</TableCell>
                  <TableCell>{t("cart_label_quantity")}</TableCell>
                  <TableCell>{t("cart_label_price")}</TableCell>
                  {deleteBtn && <TableCell align="right"></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {cart?.data.products.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.data["Basic.Name"]}</TableCell>
                      <TableCell>{item.meta.quantity}</TableCell>
                      <TableCell>{item.price.display}</TableCell>
                      {deleteBtn && (
                        <TableCell align="right">
                          <Button
                            onClick={() => {
                              removeProduct(item.key, item.data["Basic.Name"]);
                            }}
                            iconPosition="noting"
                          >
                            {t("cart_label_remove")}
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={7}></Grid>
        <Grid item xs={12} md={5}>
          <TableContainer>
            <Table className={styles["checkout-table"]}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" align="center">
                    {t("cart_label_subtotal")}
                  </TableCell>
                  <TableCell align="center">
                    {cart.data.total.display}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" align="center">
                    {t("cart_label_tax")}
                  </TableCell>
                  <TableCell align="center">
                    {cart.data.total_vat_only.display}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" align="center">
                    {t("cart_label_total")}
                  </TableCell>
                  <TableCell align="center">
                    {cart.data.total_vat.display}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default CartList;
