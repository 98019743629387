import React from "react";

// style
import styles from "./Spacer.module.scss";

const Spacer = () => {
  return <div className={styles["spacer"]}></div>;
};

export default Spacer;
