import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// utile
import { buildDate } from "utils/BuildDate";

// style
import styles from "./List.module.scss";

// img
import DefaultImg from "assets/img/img-blog-thumd-default.png";

// const
import { IMG_VARIATION_BLOG_THUMB } from "const/const";
import clsx from "clsx";

const BlogList = ({ vals = null, path = null, type = null }) => {
  const { t } = useTranslation();

  return (
    <div className={styles["list-blog"]}>
      {vals.length !== 0 &&
        vals.map((val, index) => {
          return (
            <div className={styles["list-blog-item"]} key={index}>
              <Link
                className={styles["list-blog-item-link"]}
                to={`/${path}/${val.Slug}`}
              >
                <div className={styles["list-blog-item-img"]}>
                  <img
                    src={
                      val.Drive_Item__ === null
                        ? DefaultImg
                        : val.Drive_Item.Media_Image[IMG_VARIATION_BLOG_THUMB]
                    }
                  />
                  <div className={styles["list-blog-item-date"]}>
                    <div className={styles["list-blog-item-date-info"]}>
                      {buildDate(parseInt(val.Published.unixms), "YYYY.MM")}
                    </div>
                    <div>{buildDate(parseInt(val.Published.unixms), "DD")}</div>
                  </div>
                </div>
                <p
                  className={clsx(styles["list-blog-item-title"], {
                    [styles["list-blog-item-title-dark"]]: type === "dark",
                  })}
                >
                  {val.Title}
                </p>
              </Link>
            </div>
          );
        })}
      {vals.length === 0 && (
        <p className={styles["list-blog__notfound"]}>
          {t("common_blog_notfound")}
        </p>
      )}
    </div>
  );
};

export default BlogList;
