import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { rest } from "@karpeleslab/klbfw";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import Nav from "./section/nav";
import About from "./section/About";
import Benefits from "./section/Benefits";
import Plan from "components/Fanclub/Plan";
import News from "./section/News";
import Live from "./section/Live";
import Blog from "./section/Blog";
import Gallery from "./section/Gallery";
import Box from "./section/Box";
import PlanList from "./section/PlanList";

// utils
import { memberIsActive } from "utils/MemberRequired";

// style
import styles from "./Fanclub.module.scss";

const Fanclub = () => {
  const { t } = useTranslation();
  const [user] = useRest("User:get");
  const [memberShip, setMemberShip] = useState(false);

  useEffect(() => {
    if (user !== null && user?.data !== null) {
      rest("Membership/@fanclub/User/@")
        .then((data) => {
          setMemberShip(memberIsActive(data));
        })
        .catch(() => {
          setMemberShip(false);
        });
    } else {
      setMemberShip(false);
    }
  }, [user, setMemberShip]);

  return (
    <>
      <InnerLayout headType="white" title={t("common_fanclub")}>
        <Helmet>
          <title>{`${t("common_fanclub")} - ${t("common_title")}`}</title>
          <meta
            property="og:title"
            content={`${t("common_fanclub")} - ${t("common_title")}`}
          />
          <meta
            property="og:site_name"
            content={`${t("common_fanclub")} - ${t("common_title")}`}
          />
        </Helmet>
        {memberShip === false && (
          <>
            <Nav />
            <div
              className={`${styles["content-wrapper"]} ${styles["content-wrapper-fanclub"]}`}
            >
              <div className={styles["content-container"]}>
                <About />
                <PlanList />
                <Benefits />
              </div>
            </div>
            <Plan />
          </>
        )}
        {memberShip !== false && (
          <>
            <Nav />
            <div
              className={`${styles["content-wrapper"]} ${styles["content-wrapper-fanclub"]}`}
            >
              <div className={styles["content-container"]}>
                <News />
              </div>
            </div>
            <div className={`${styles["content-wrapper"]}`}>
              <div className={styles["content-container"]}>
                <Live />
              </div>
            </div>
            <div className={`${styles["content-wrapper"]}`}>
              <div className={styles["content-container"]}>
                <Blog />
              </div>
            </div>
            <div className={`${styles["content-wrapper"]}`}>
              <div className={styles["content-container"]}>
                <Gallery />
              </div>
            </div>
            <div className={`${styles["content-wrapper"]}`}>
              <div className={styles["content-container"]}>
                <Box />
              </div>
            </div>
          </>
        )}
      </InnerLayout>
    </>
  );
};

export default Fanclub;
