import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { rest } from "@karpeleslab/klbfw";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";

// component

// material ui
import { Typography } from "@material-ui/core";

// style
import styles from "../Header.module.scss";

// img
import Cara from "assets/img/bg-mew.png";
import Logo from "assets/img/img-logo-fanclub.png";
import Cat from "assets/img/icon-cat-login.png";

const HeaderWhite = ({ title, titleVariant = "h1" }) => {
  const { t } = useTranslation();
  const [user] = useRest("User:get");
  const userRefresh = useRestRefresh("User:get");

  const logOut = () => {
    rest("User/@:logout", "POST").then(() => {
      userRefresh();
    });
  };

  return (
    <>
      <header className={styles["header-white"]}>
        <div className={styles["header-white-inner"]}>
          <div className={styles["header-white-cara"]}>
            <img src={Cara} alt={t("common_title")} />
          </div>
          <div className={styles["header-white-title-container"]}>
            <img src={Logo} alt={t("common_title")} />
            <Typography
              align="center"
              className={styles["header-white-title"]}
              variant={titleVariant}
            >
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Typography>
          </div>
        </div>
      </header>
      {user?.data === null && (
        <div className={styles["header-white-login"]}>
          <Link
            to="/login?return_to=/fanclub"
            className={styles["header-white-login-btn"]}
          >
            <span>
              <img src={Cat} alt={t("common_login")} />
              {t("common_login")}
            </span>
          </Link>
        </div>
      )}
      {user !== null && user?.data !== null && (
        <div className={styles["header-white-user"]}>
          <p className={styles["header-white-user-text"]}>
            {t("fanclub_user_welcom", {
              name: user?.data.Profile.Display_Name,
            })}
          </p>
          <p
            className={styles["header-white-user-logout"]}
            onClick={() => {
              logOut();
            }}
          >
            {t("common_logout")}
          </p>
        </div>
      )}
    </>
  );
};

export default HeaderWhite;
