import React from "react";
import { useTranslation } from "react-i18next";
//material ui

// components
import ContentTitle from "components/Typography/ContentTitle";
import Paragraph from "components/Typography/Paragraph";

// style
import styles from "./Profile.module.scss";

// img
import ProfileImg from "assets/img/img-profile.png";

const Profile = () => {
  const { t } = useTranslation();

  return (
    <div className={`${styles["content-wrapper"]}`}>
      <div className={styles["content-container"]}>
        <ContentTitle>{t("common_profile")}</ContentTitle>
        <figure className={styles["profile-img"]}>
          <img src={ProfileImg} alt={t("common_profile")} />
        </figure>
        <Paragraph className={styles["profile-text"]}>
          {t("top_profile_text")}
        </Paragraph>
      </div>
    </div>
  );
};

export default Profile;
