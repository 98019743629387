import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TwitterTimelineEmbed } from "react-twitter-embed";

// material ui
import SnsTitle from "components/Typography/SnsTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

// style
import styles from "./Sns.module.scss";

const Twitter = () => {
  const { t } = useTranslation();
  const [load, setLoad] = useState(true);

  return (
    <div className={`${styles["content-wrapper"]}`}>
      <div className={styles["content-container"]}>
        <SnsTitle>{t("common_twitter")}</SnsTitle>
        <div className={styles["twitter-container"]}>
          {load && (
            <div className={styles["twitter-load"]}>
              <CircularProgress color="secondary" />
            </div>
          )}
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="Mew_tcmp"
            options={{ height: 400 }}
            onLoad={() => {
              setLoad(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Twitter;
