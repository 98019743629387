import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";

// component
import InputForm from "components/Forms/Input";
import Country from "components/Forms/Country";

const BillingInputs = ({
  fName,
  setFName,
  lName,
  setLName,
  zip,
  setZip,
  province,
  setProvince,
  city,
  setCity,
  address,
  setAddress,
  phone,
  setPhone,
  country,
  setCountry,
  Countrys,
  load,
}) => {
  const { t } = useTranslation();
  const [displayFormat, setDisplayFormat] = useState(null);

  useEffect(() => {
    if (country !== "") {
      rest(`Country/${country}`).then((data) => {
        setDisplayFormat(data.data.Display_Format);
      });
    }
  }, [country]);

  const viewFormat = () => {
    return displayFormat.map((array) => {
      return array.map((item, index) => {
        switch (item) {
          case "!":
          case "!〒":
          case "Address1":
            break;
          case "Zip":
            return (
              <InputForm
                key={index}
                type="text"
                value={zip}
                name="user-zip"
                label={t("mypage_billing_zip")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_zip"),
                })}
                handleChange={(event) => {
                  setZip(event.target.value);
                }}
                required={true}
                disabled={load}
              />
            );
          case "Province":
            return (
              <InputForm
                key={index}
                type="text"
                value={province}
                name="user-province"
                label={t("mypage_billing_province")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_province"),
                })}
                handleChange={(event) => {
                  setProvince(event.target.value);
                }}
                required={true}
                disabled={load}
              />
            );
          case "City":
            return (
              <InputForm
                key={index}
                type="text"
                value={city}
                name="user-city"
                label={t("mypage_billing_city")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_city"),
                })}
                handleChange={(event) => {
                  setCity(event.target.value);
                }}
                required={true}
                disabled={load}
              />
            );
          case "Address":
            return (
              <InputForm
                key={index}
                type="text"
                value={address}
                name="user-address"
                label={t("mypage_billing_address")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_address"),
                })}
                handleChange={(event) => {
                  setAddress(event.target.value);
                }}
                required={true}
                disabled={load}
              />
            );
          default:
            break;
        }
      });
    });
  };

  return (
    <>
      {displayFormat !== null && (
        <>
          <Country
            name="user-country"
            label={t("mypage_billing_country")}
            value={country}
            array={Countrys?.data}
            handleChange={(event) => {
              setCountry(event.target.value);
            }}
            required={true}
            disabled={load}
          />
          {country === "JP" && (
            <>
              <InputForm
                type="text"
                value={lName}
                name="user-lname"
                label={t("mypage_billing_lname")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_lname"),
                })}
                handleChange={(event) => {
                  setLName(event.target.value);
                }}
                required={true}
                disabled={load}
              />
              <InputForm
                type="text"
                value={fName}
                name="user-fname"
                label={t("mypage_billing_fname")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_fname"),
                })}
                handleChange={(event) => {
                  setFName(event.target.value);
                }}
                required={true}
                disabled={load}
              />
            </>
          )}
          {country !== "JP" && (
            <>
              <InputForm
                type="text"
                value={fName}
                name="user-fname"
                label={t("mypage_billing_fname")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_fname"),
                })}
                handleChange={(event) => {
                  setFName(event.target.value);
                }}
                required={true}
                disabled={load}
              />
              <InputForm
                type="text"
                value={lName}
                name="user-lname"
                label={t("mypage_billing_lname")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_lname"),
                })}
                handleChange={(event) => {
                  setLName(event.target.value);
                }}
                required={true}
                disabled={load}
              />
            </>
          )}

          {viewFormat()}

          <InputForm
            type="tel"
            value={phone}
            name="user-phone"
            label={t("mypage_billing_phone")}
            placeholder={t("input_field_placeholder", {
              text: t("mypage_billing_phone"),
            })}
            handleChange={(event) => {
              setPhone(event.target.value);
            }}
            required={true}
            disabled={load}
          />
        </>
      )}
    </>
  );
};

export default BillingInputs;
