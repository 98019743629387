import React, { useState, useEffect } from "react";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import { error } from "components/Toast/Toast";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Helmet from "components/Helmet/Helmet";
import Input from "components/Forms/Input";
import Button from "components/Btns/Btn";
import TextAreaForm from "components/Forms/TextArea";

// utils
import { validateEmail } from "utils/Validation";

// style
import styles from "./Contact.module.scss";

const Contact = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [massage, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);

  // send
  const [sending, setSending] = useState(false);
  const [sendComp, setSendComp] = useState(false);

  const send = () => {
    setSending(true);

    const query = {
      Email: email,
      Name: name,
      To: "@support",
      Subject: subject,
      Message: massage,
    };

    rest("Support/Ticket", "POST", query)
      .then(() => {
        setSendComp(true);
        setSending(false);
      })
      .catch((err) => {
        setSending(false);
        error(t(`error_${err.token}`), false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (
      !validateEmail(email) ||
      !subject.trim() ||
      !email.trim() ||
      !massage.trim()
    ) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [email, name, massage, subject]);

  return (
    <>
      <InnerLayout headType="lower" title={t("common_contact")}>
        <Helmet>
          <title>{`${t("common_contact")} - ${t("common_title")}`}</title>
          <meta
            property="og:title"
            content={`${t("common_contact")} - ${t("common_title")}`}
          />
          <meta
            property="og:site_name"
            content={`${t("common_contact")} - ${t("common_title")}`}
          />
        </Helmet>
        <div className={`${styles["content-wrapper"]}`}>
          <div className={styles["content-container"]}>
            {sendComp && (
              <>
                <p className={styles["contact-comp-text"]}>
                  {t("contact_send_comp_txt")}
                </p>
                <div className={styles["contact-btn"]}>
                  <Button to="/">{t("common_btn_top")}</Button>
                </div>
              </>
            )}
            {!sendComp && (
              <>
                <Input
                  name="name"
                  label={t("contact_label_name")}
                  handleChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  placeholder={t("contact_label_name")}
                  helperText=""
                  disabled={sending}
                />
                <Input
                  name="subject"
                  label={t("contact_label_subject")}
                  handleChange={(e) => {
                    setSubject(e.target.value);
                  }}
                  value={subject}
                  placeholder={t("contact_place_subject")}
                  helperText=""
                  disabled={sending}
                />
                <Input
                  name="email"
                  label={t("contact_label_email")}
                  handleChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  placeholder={t("contact_label_email")}
                  helperText=""
                  type="email"
                  disabled={sending}
                />
                <TextAreaForm
                  name="message"
                  label={t("contact_label_massage")}
                  handleChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  value={massage}
                  placeholder=""
                  helperText=""
                  disabled={sending}
                />
                <div className={styles["contact-btn"]}>
                  <Button
                    onClick={send}
                    iconPosition="noting"
                    disabled={disabled || sending}
                  >
                    {t("contact_btn")}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </InnerLayout>
    </>
  );
};

export default Contact;
