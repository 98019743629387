import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// components
import HeaderTop from "./section/HeaderTop";
import HeaderLower from "./section/HeaderLower";
import HeaderWhite from "./section/HeaderWhite";
import HeaderFanclub from "./section/HeaderFanclub";
import HeaderLive from "./section/HeaderLive";
import Nav from "./section/Nav";

// style
import styles from "./Header.module.scss";

// img
import iconMenu from "assets/img/icon-menu.png";
import iconClose from "assets/img/icon-close.png";

const Header = ({
  headType = "top",
  title = "common",
  titleVariant = "h1",
  content = null,
  path = null,
}) => {
  const { t } = useTranslation();
  const [menuShow, setMenuShow] = useState(false);
  const [fanclub, setFanclub] = useState(false);

  const typeHandller = () => {
    switch (headType) {
      case "notFound":
        return null;
      case "white":
        return <HeaderWhite title={title} titleVariant={titleVariant} />;
      case "lower":
        return (
          <HeaderLower title={title} titleVariant={titleVariant} path={path} />
        );
      case "live":
        return <HeaderLive />;
      case "fanclub":
        return (
          <HeaderFanclub
            title={title}
            titleVariant={titleVariant}
            content={content}
          />
        );
      default:
        return <HeaderTop />;
    }
  };

  return (
    <>
      <div className={styles["header-container"]}>
        {typeHandller()}
        <button
          onClick={() => {
            setMenuShow(!menuShow);
            if (!menuShow) setFanclub(false);
          }}
          className={styles["header-btn"]}
        >
          {menuShow && <img src={iconClose} alt={t("common_close_btn")} />}
          {!menuShow && <img src={iconMenu} alt={t("common_menu_btn")} />}
        </button>
      </div>
      <Nav
        menuShow={menuShow}
        setMenuShow={setMenuShow}
        fanclub={fanclub}
        setFanclub={setFanclub}
      />
    </>
  );
};

export default Header;
