import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";

// components

// style
import styles from "./Fanclub.module.scss";

// img
import Cat from "assets/img/icon-cat-white.png";
import iconLockGold from "assets/img/icon-lock-gold.png";
import iconLockSilver from "assets/img/icon-lock-silver.png";

const PlanCard = ({ val, type = null }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={clsx(
          `${styles["fanclub-plan"]} ${
            styles[
              `fanclub-plan-${val.Catalog_Product["Basic.Name"].toLowerCase()}`
            ]
          }`,
          {
            [styles["fanclub-plan-subscribe"]]: type === "subscribe",
          }
        )}
      >
        <div
          className={`${styles["fanclub-plan-mark"]} ${
            styles[
              `fanclub-plan-mark-${val.Catalog_Product[
                "Basic.Name"
              ].toLowerCase()}`
            ]
          }`}
        >
          <img
            src={Cat}
            alt={t(
              `fanclub_plan_${val.Catalog_Product["Basic.Name"].toLowerCase()}`
            )}
          />
        </div>
        <h3
          className={styles["fanclub-plan-title"]}
          dangerouslySetInnerHTML={{
            __html: t(
              `fanclub_plan_${val.Catalog_Product["Basic.Name"].toLowerCase()}`
            ),
          }}
        />
        <p
          className={clsx(styles["fanclub-plan-text"], {
            [styles["fanclub-plan-text-subscribe"]]: type === "subscribe",
          })}
          dangerouslySetInnerHTML={{
            __html: t(
              `fanclub_plan_${val.Catalog_Product[
                "Basic.Name"
              ].toLowerCase()}_price`,
              { price: val.Catalog_Product.Price.tax.display }
            ),
          }}
        />
        {type !== "subscribe" && (
          <>
            <dl
              className={`${styles["fanclub-plan-benefits"]} ${
                styles[
                  `fanclub-plan-benefits-${val.Catalog_Product[
                    "Basic.Name"
                  ].toLowerCase()}`
                ]
              }`}
            >
              <dt>{t("fanclub_plan_benefits_title")}</dt>
              <dd>
                <ul>
                  {val.Catalog_Product["Basic.Name"] === "GOLD" && (
                    <>
                      <li>{t("fanclub_benefits_content_title")}</li>
                      <li>{t("fanclub_benefits_live_title")}</li>
                      <li>{t("fanclub_benefits_anniversary_title")}</li>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("fanclub_benefits_album_title"),
                        }}
                      ></li>
                      <li>{t("fanclub_benefits_message_title")}</li>
                    </>
                  )}
                  {val.Catalog_Product["Basic.Name"] === "SILVER" && (
                    <>
                      <li>{t("fanclub_benefits_content_title")}</li>
                      <li>{t("fanclub_benefits_live_title")}</li>
                      <li>{t("fanclub_benefits_anniversary_title")}</li>
                      <li className={styles["gold"]}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("fanclub_benefits_album_title"),
                          }}
                        ></span>
                        <img
                          src={iconLockGold}
                          alt={t("fanclub_benefits_album_title_alt")}
                        />
                      </li>
                      <li className={styles["gold"]}>
                        {t("fanclub_benefits_message_title")}
                        <img
                          src={iconLockGold}
                          alt={t("fanclub_benefits_message_title")}
                        />
                      </li>
                    </>
                  )}
                  {val.Catalog_Product["Basic.Name"] === "BRONZE" && (
                    <>
                      <li>{t("fanclub_benefits_content_title")}</li>
                      <li className={styles["silver"]}>
                        {t("fanclub_benefits_live_title")}
                        <img
                          src={iconLockSilver}
                          alt={t("fanclub_benefits_live_title")}
                        />
                      </li>
                      <li className={styles["silver"]}>
                        {t("fanclub_benefits_anniversary_title")}
                        <img
                          src={iconLockSilver}
                          alt={t("fanclub_benefits_anniversary_title")}
                        />
                      </li>
                      <li className={styles["gold"]}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("fanclub_benefits_album_title"),
                          }}
                        ></span>
                        <img
                          src={iconLockGold}
                          alt={t("fanclub_benefits_album_title_alt")}
                        />
                      </li>
                      <li className={styles["gold"]}>
                        {t("fanclub_benefits_message_title")}
                        <img
                          src={iconLockGold}
                          alt={t("fanclub_benefits_message_title")}
                        />
                      </li>
                    </>
                  )}
                </ul>
              </dd>
            </dl>
            <Link
              to={`/subscribe/${val.Membership_Plan__}`}
              className={styles["fanclub-plan-add"]}
            >
              {t("fanclub_add", {
                rank: val.Catalog_Product["Basic.Name"],
              })}
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default PlanCard;
